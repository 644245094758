@import 'partials/mixins';
@import 'partials/color';

.ys-display-only-mobile {
  display: block;
  @include media-query($medium-up) {
    display: none;
    background: none;
  }
}
.ys-display-only-desktop {
  display: none;
  background: none;
  @include media-query($large-up) { display: block; }
}
.ys-display-only-tablet {
  display: none;
  background: none;
  @include media-query($medium) { display: block; }
}
.row.panels {
  padding-left: 0px; 
  padding-bottom: 5px;
  margin-right: -30px;
  @include media-query($small) {
    padding: 0px; 
    margin-right: -15px;
  }
}
.clickable { cursor: pointer; }
.panels {
  @include media-query($small) { border-bottom: solid 10px white; }
  .panel {
    @extend .row;
    background: #ededed;
    overflow: hidden;
    position: relative;
    border-radius: 0px;
    margin-bottom: 0;
    transition-property: all;
    transition-duration: 0.7s;
    border: 5px solid white ;
    display: flex;

    @include media-query($small) {
      border-right: 0;
      border-left: 0;
    }
    @extend .justify-content-center;

    .section {
      // Fix for safari
      top: 37%;
      @extend .col-12;
      @extend .align-self-center;
      z-index: 100;
      position: absolute;
      text-align: center;
      &.ys-panel-bottom { bottom: 15%; }
      &.ys-panel-top { top: 15%; }
      &.sub-header {
        position: relative;
        display: block;
        h3 {
          color: #000;
          max-height: inherit;
          margin: auto;
          margin-bottom: 20px;
          padding: 0 20px;
        }
      }
    }
    img {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      opacity: 0.98;
    }
    &:hover { }

    .btn {
      color: black;
      border-radius: 0px;
      letter-spacing: 1px;
      display: block;
      font-family: 'GloberBold', Arial, Helvetica, sans-serif;
      line-height: normal;
      font-size: 14px;
      border-bottom: 2px solid black;
      display: inline-block;
    }

    .btn-primary {
      color: $color-white;
      background: black;
      text-transform: capitalize;
      border: none;
      border-radius: 0px;
      padding: 10px 20px 6px 20px;
      transition: .7s;
      line-height: 1;
      @include media-query($large-up) {
        font-size: 22px;
        text-transform: uppercase;
      }
      &:hover { opacity: .7; }
      &.btn-primary_white {
        color: Black;
        background:$color-white;
      }
    }
    &.v2 { background: rgb(218, 223, 222); }
    &.v3 { background: rgb(65, 65, 65); }
    &.center {
      .section { align-items: center; }
    }
    &.simple {
      position: relative;
      width: 100%;
      @include media-query($small) { width: initial; }
      .btn-primary { position: relative; }
      &.v2 {
        .btn-primary{
          color: black;
          background: white;
        }
      }
    }

    &.top-right {
      .section {
        text-align: right;
        top: $grid-gutter-width + 20px;
        left: auto;
        right: $grid-gutter-width + 20px ;
        text-align: right;
        height: inherit;
        .bnt { margin-right: 10px; }
      }
    }

    &.top-left {
      .section {
        right: auto; left: 20px;
        text-align: left;
      }
    }

    &.bottom-left {
      .section {
        right: auto;
        left: $grid-gutter-width + 20px ;
        bottom: $grid-gutter-width + 20px;
        top: inherit;
        text-align: left;
      }
    }

    &.bottom-right {
      .section{
        left: auto;
        top: inherit;
        right: $grid-gutter-width + 20px;
        bottom: $grid-gutter-width + 20px;
        text-align: left;
      }
    }

    h3 {
      margin-bottom: 19px;
      color: white;
      @include media-query($small) { font-size: 18px; }
    }
  }
}

.insta-panel {
  padding: 40px 0;
  background: $color-white;
  img {
    float: left;
    margin:0;
    width: 100%;
    height: auto;
  }
}

.video-player {
  height: 600px;
  background: black;
  display: flex;
  .icon {
    width: 80px;
    text-align: center;
    margin: 0 auto;
    display: block;
    flex-direction: column;
  }
}

// Instagram
.swiper-container-instagram {
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0px;
  height: 300px;
  margin: 20px 10px;
  @include media-query($medium-up) { margin: 40px 0; }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 300px !important;
    padding: 0 ;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    a {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
  .swiper-slide:nth-child(2n) { width: 300px; }
  .swiper-slide:nth-child(3n) { width: 300px; }
}
