.widget-sidebar ul li a:hover{
  background:#0071d1;
  right: 50px;
}

body{
min-height: 800px;
}

.widget-sidebar{
width: 60px;
height: 300px;
position: fixed;
// right: -500px;
// right: 0px;
display: none;
top: 40%;

z-index: 11;

.visible-xs-block{
  display: none;
}

.widget-sidebar-wrapper{
    position: absolute;
    right: 0;
      width: 0px;
      overflow: hidden;
      transition: .5s all ;

    &.active{
      width: 500px;
      display: block;
      padding-right: 76px;
    }
}
&.visible{
  display: block;
}

&.active{
  width: 500px;
}

ul{
  display: block;
  width: 70px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 800;
  height: 300px;
  background: #0071d1;
  overflow: hidden;

  li{
    display: block;
    a{
      font: 9px/11px Verdana ;
      text-align: center;
      display: block;
      padding: 8px 5px;
      background: #0071d1;
      text-transform: uppercase;
      color: white;

      &:hover{
        text-decoration: none;
      }

      span{
        color: white;
        line-height: 11px;
        font-family: 'globerRegular';
      }
      &.button1{
        border-bottom: 2px solid white;
      }
      &.active{
        background: #363131;
        transition: .2s all ;
      }

      &.button2 {
        height: 200px;
      }
    }
  }
  img{
    width: 50px;
    display: block;
    margin: 0 auto;
    margin-bottom: 7px;
    margin-top: 10px;
  }
}

.widget-sidebar-wrapper{
  position: relative;
  margin-right: 69px;
  right: -500px;
  background: #363131;
  padding: 10px 30px;
  height: 300px;
  text-align: center;

  &.active{
    right:  0px;
    display: block;
  }

  .widget-sidebar-content{
    padding: 30px 20px 20px 20px;
    display: none;
    &.active{
      display: block;
    }
    p{
      color: white;
      margin-bottom: 20px;
    }

    .btn{

      font: 17px 'GloberRegular', Arial, Helvetica, sans-serif;
      display: inline-block;
      padding: 2px 20px 0px 20px;
      line-height: 33px;
      letter-spacing: 1px;
      color: #fff;
      background-color: #0071d1;
      border-radius: 0;
      margin: 12px 0 50px 0;
      text-transform: uppercase;

      font-size: 14px;
      margin-bottom: 20px;
    }

    h4{
      font-size: 20px;
      color: white;
      text-transform: uppercase;
      padding-bottom: 21px;
    }
  }
}

}

/* hide widget on larger screens */
@media only screen and (min-width: 769px) {
  .widget-sidebar {
    display: none !important;
  }
}

/* Phone Styles */
@media only screen and (max-width: 768px) {
  .widget-sidebar {
      bottom: 0;
      top: inherit;
      width: 100%;
      height: 54px;

      &.active{
        animation: none;
        right: -500px;
      }
      .hidden-xs{
        display: none;
      }

      .visible-xs-block{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ul{
        width: 100%;
        overflow: hidden;
        margin-right: -1px;
        padding-right: 1px;
        li{
          width: 50%;
          float: left;
          overflow: hidden;
          border-right: solid 1px white;
          a{
            display: block;
            overflow: hidden;
            padding: 7px 5px;
            span{
              line-height: normal;
              display: block;
              float: left;
              width: 100px;
              padding-top: 5px;
              font-size: 14px;
              float: left;
            }
            img{
              width: 40px;
              height: 40px;
              float: left;
              margin: 0 !important;
            }
          }
        }

      }
      .widget-sidebar-wrapper{
        width: 100%;
        &.active{
          bottom: 259px;
        }
      }
  }
}
