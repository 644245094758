@use "sass:math";
@use "sass:list";

@import "bootstrap";
@import "reset";
@import "colors";

@import "breadcrum";
@import "partials/variables";
@import "partials/mixins";
@import "partials/color";
@import "~NodeModules/swiper/swiper-bundle.min.css";
@import "./themes/swiper/swiper.scss";
@import "animation";
@import "modal";
@import "partials/fonts";
@import "carousel";
@import "reviews";
@import "heads";
@import "form";
@import "checkout";
@import "extras";
@import "panels";
@import "tags";
@import "footer";
@import "other";
@import "buttons";
@import "layout";
@import "popups";
@import "social";
@import "shame";
@import "./components/cart/cart-overlay";

/* Navigation */
@import 'navigation';

/* Alert */
@import 'alert';

/* Addons */
@import 'perfectfootbed';
@import 'sidebar-widget';
@import 'footbed-finder';
@import 'footbed-tutorial';
@import 'guarantee';

.SoleApp {
  @import "~/css/pages/product-list/_cloaking.scss";
  @import "~/css/pages/product-list/_loading.scss";
}

.text-black {
  color: #000 !important;
}

img.place-holder {
  width: 100%;
}

.no-content {
  display: none;
}

p {
  margin: 10px 0 20px 0;
  font-family: "GloberRegular", Arial, Helvetica, sans-serif;
  a,
  a:link,
  a:visited {
    color: $color-link;

    &.icon-sole_rounded {
      color: white;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @extend .row;

  strong {
    color: black;
    font-family: "GloberSemiBold", Arial, Helvetica, sans-serif;
    font-weight: inherit;
  }
}

h3, h4, h5, .h3, .h4, .h5 {
  text-transform: unset !important;
}

.space1,
.space2,
.space3,
.space4,
.space5,
.space6,
.space7 {
  display: block;
}
.space1 {
  height: 10px;
}
.space2 {
  height: 20px;
}
.space3 {
  height: 30px;
}
.space5 {
  height: 50px;
}
.space4 {
  height: 40px;
}
.space6 {
  height: 60px;
}
.space7 {
  height: 70px;
}

.diviser {
  border-bottom: 1px solid lighten($color-med-grey, 12%);
  display: block;
  height: 2px;
  margin-bottom: 23px;
}

.oldprice {
  color: $color-dark-grey;
  text-decoration: line-through;
  vertical-align: top;
}

span.text-right {
  float: right;
}

body {
  color: black;
}

// Color
.color-warn {
  color: $color-contrast;
  strong {
    color: $color-contrast;
  }
}

.color-alert {
  color: $color-error;

  strong {
    color: $color-error;
  }
}

// sortByLowPrice
.bold-text {
  font-size: 16px; 
  font-family: "GloberxBold", Arial, Helvetica, sans-serif;
}


.padding-right-min {
  margin: 0px !important;
  padding: 0 !important;
  padding-right: 5px !important;
}

.product-list {
  opacity: 0.2;
}

.carousel {
  background: #acaea9;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.icon-new {
  width: 22px;
  height: 22px;
  margin: 0 10px;
  vertical-align: middle;
}

.icon-international {
  margin: -6px !important;
  height: 25px !important;
  width: auto !important;
}

.sale {
  text-decoration: line-through;
}
.fix-bottom {
  overflow: hidden;
  height: 55px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #d5d5d5;
  right: 0px;
  z-index: 10000;

  .btn-icon {
    padding: 10px 6px;
    border: solid 1px grey;
    display: inline-block;
    margin: 8px 5px;
    vertical-align: middle;
    height: 40px;
  }
  .btn-icon:before,
  .frame_before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  img {
    vertical-align: middle;
    max-height: 25px;
    max-width: 160px;
  }

  /* Move this to conditional comments */
  .frame {
    list-style: none;
    behavior: expression(
      function(t){t.insertAdjacentHTML(
          "afterBegin",
          '<span class="frame_before"></span>'
        )
        ; t.runtimeStyle.behavior = "none" ; }(this)
    );
  }

  .col-xs-6:first-child {
    margin-right: solid 1px black;
  }

  .btn-block {
    height: 55px;
    font-size: 16px;
    text-decoration: none;
    padding-top: 15px;
  }
}

.product-list {
  .product-list-button {
    overflow: hidden;
    display: block;
    margin-bottom: 12px;

    h4 {
      font-size: 15px;
      margin-bottom: 5px;
    }

    p {
      margin: 3px 0;
    }
  }
  img {
    width: 100%;
  }
  .price {
    font-size: 12px;
    margin-bottom: 15px;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.search-bar {
  position: relative;
  margin: 25px 0 20px 0;
  .glyphicon {
    position: absolute;
    right: 8px;
    top: 10px;
    font-size: 16px;
  }
}

.underline {
  border-bottom: solid 1px black;
}

.product-list-view {
  img {
    width: 100%;
  }
  .item {
    margin-bottom: 20px;
    border-bottom: solid 1px;
    padding-bottom: 15px;
  }
}

.grey-bg {
  background: #b5b5b5;
}

.swiper-slide {
}
.swiper-slide:nth-child(2n) {
  width: 60%;
}
.swiper-slide:nth-child(3n) {
  width: 40%;
}

.carousel-control.left,
.carousel-control.right {
  background: none;
}

.hero {
  background: #e0dfdf;
}

.carousel-inner {
  .item {
    height: 200px;
    background: darken($grey, 10%);
  }
}

.jumbotron {
  margin-bottom: 0;
  .btn-primary {
    background: black;
    color: #fcf5f5;
  }

  .container & {
    border-radius: 0;
  }

  &.v2 {
    background: #343030;

    h1,
    p,
    h2,
    h3 {
      color: white;
    }

    .btn-primary {
      background: #fcf5f5;
      color: black;
    }
  }

  &.v3 {
    background: #a8a3a3;

    h1,
    p,
    h3 {
      color: white;
    }

    .btn-primary {
      background: #fcf5f5;
      color: black;
    }
  }
}

.moreinfo {
  font-size: 19px;
  border-bottom: solid 1px;
  padding: 12px;
  display: block;
  position: relative;
  color: black;
  margin-bottom: 4px;

  text-decoration: none;
  &:after {
    position: absolute;
    content: "+";
    right: 10px;
    font-size: 19px;
  }
}

.border-5 {
  border-style: solid;
  border-width: 5px;
}

.footer {
  background: #343030;
  padding: 25px 0;

  h1,
  p,
  h3,
  h4 {
    color: white;
    padding: 10px 0;
    font-size: 14px;
  }
}

.product-tbn {
  padding: 0 15px;
  img {
    width: 31%;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
  }
}

.showBtn {
  padding: 20px 0;
  display: block;
}

.carousel-caption {
  p {
    text-shadow: none;
    color: white;
  }
}

.white-bg {
  background: white;

  .carousel-inner .item {
    height: 360px;
  }
  .carousel-control {
    color: #696566;
    text-shadow: none;
  }
  .item {
    img {
      width: 100%;
    }
    background: white;
    h3,
    p,
    h1,
    h2,
    h4 {
      color: black;
      text-shadow: none;
    }
  }
}

.wh-aspect {
  aspect-ratio: list.slash(attr(width), attr(height));
}

.modal.fade.show {
  width: 100vw;
}
