#checkout{
  @extend .container;
  padding-top: 30px;

  .main{
    @extend .col-sm-7;
  }

  .aside{
    @extend .col-sm-5;

    .btn-block{
      margin-left: 0;
      margin-right: 0;
    }

    .alertBox p{
      margin: 0;
    }
    

    &-content{
      padding-left: $grid-gutter-width;
      border-left: 1px solid black;
      padding-bottom: $grid-gutter-width * 0.5;
      margin-bottom: $grid-gutter-width; 
    }

    hr{
      margin: $grid-gutter-width * 0.5 0;
    }

    p strong{
      margin-bottom: $grid-gutter-width * 0.1;
      display: inline-block;
    }

    h6.custom{
      font-size: 20px;
      margin-bottom: 0px;
    }

    h4, .h4, h5, .h5, h6, .h6{
      margin-bottom: $grid-gutter-width * 0.5;

      a{
        color: $color-black;

        &:hover{
          text-decoration: none;
        }

        &.icon-sole_rounded{
          color: white;
        }
      }

      & strong{
        text-transform: capitalize;
        font-family: 'GloberBold', Arial, Helvetica, sans-serif;
        font-weight: normal;
      }
    }

    p{
      margin: $grid-gutter-width * 0.5 0 $grid-gutter-width * 0.9 0;
    }
  }

  .modal-body{
    padding-left: 0;
    padding-right: 0;
  }
}

.cart-product-list{
  &_wrapper{
    @extend .col-12;

    border-bottom: solid  1px ;
    padding: $grid-gutter-width 0;

    #checkout .aside & {
      border-bottom: none ;
    }

    &:first-of-type{
      padding-top: $grid-gutter-width*0.5;
    }

    ul{
      padding: 0;
      padding-top: $grid-gutter-width*0.5;

      li{
        margin: 0;
        display: inline;
        a{
          color: $color-link;
          padding: 0 10px;

          &:first-child{
            padding-left: 0;
          }
        }
      }
    }
  }

  &_image{
    @extend .col-sm-5;
    @extend .col-5;
    @extend .nopadding;

    img{
      width: 100%;
    }
  }

  &_price{
    font-size: 24px;
    color: $color-contrast;
    padding: 5px 0;
  }

  &_header{
    @extend .row;
    position: relative;
    h3{
      @extend .col-sm-12;
      margin-bottom:5px;

      #checkout .aside & {
        margin: 0 0 9px 0;
      }
    }

    a{
      @extend .underline;
    }

    .close{
      position: absolute;
      top: 5px;
      right: 0;
      font-size: 15px;
    }
  }

  &_info{
    @extend .col-sm-7;
    @extend .col-7;
    padding-left: $grid-gutter-width;

    @include media-query($medium-up) {
    }

    .btn{
      margin-left: 0;
    }

    p{
      margin: 2px 0 5px 0;

      #checkout .aside & {
        margin: 0;
      }
    }
  }
}

.cart-product-list_info{
}
