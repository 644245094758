@import "~/css/landing/fonts/_font-styling.scss";
@import "~/css/_colors";

@each $size in 12 13 14 16 18 20 {
  .__font-#{$size} {
    font-size: $size px;
  }
}

.text-decoration-none {
  text-decoration: none !important;
}

.Cart__overlay {
  color: #000;
  width: 100vw;
  @extend .globerBold;
  &-toggle-div {
    width: 100vw;
  }
  &-position {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: fixed;
    z-index: 20001;
    top: 0;
  }
  &-container {
    box-shadow: 0px 10px 10px 0px #0000003b;
    max-width: 400px;
    width: 100%;
    overflow-y: auto;
    max-height: 90vh;
  }
  &-close {
    position: relative;
    top: -6px;
  }
  &-title {
    &--font {
      @extend .__font-20;
      @extend .globerBold;
    }
  }
  &-items-total-quantity {
    @extend .__font-20;
    color: #99999b;
    &--font {
      @extend .globerBold;
    }
    &--pl {
      padding-left: 2px;
    }
    &--js{
      color: #000;
    }
  }
  &-items-list {
    &--layout {
      max-height: 280px;
    }
    &--overflow {
      overflow-x: visible;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  &-product {
    margin-bottom:  1.5rem;
    &:last-child {
      margin-bottom: 0rem;
    }
    line-height: normal;
    min-height: 110px;
    width: 350px;
    &-with-stock-message {
      margin-bottom:  1.5rem;
      &:last-child {
        margin-bottom: 0rem;
      }
      line-height: normal;
      min-height: 155px;
      width: 350px;
    }
    &-container {
      margin-right: 5px;
    }
    &-image {
      min-height: 110px;
      min-width: 120px;
      &--bgcolor {
        background-color: #f5f5f5;
      }
    }
    &-name {
      color: $SOLE-black;
      &--font {
        font-size: 16px;
        @extend .globerRegular;
      }
    }
    &-size {
      color: #5c5c5c;
      margin: 1px 0;
      @extend .globerRegular;
      font-size: 12.8px;
    }
    &-color {
      @extend .globerRegular;
      font-size: 12.8px;
      color: #5c5c5c;
    }
    &-qandp {
      &-quantity {
        border: 3px solid #dadada;
        &-add, &-remove {
          & svg {
            fill: $SOLE-disabled;
            &:hover {
              fill: $SOLE-black
            }
          }
        }
        &-counter {
          @extend .globerSemiBold;
          font-size: 14px;
          color: $SOLE-black;
          min-width: 50px;
          text-align: center;
          margin-top: 0.25rem;
        }
      }
      &-price {
        font-size: 14px;
        color: $SOLE-black;
        &-regular {
          &-discounted {
            color: $SOLE-danger;
            text-decoration: line-through;
          }
        }
      }
    }
    &-stock-message {
      width: 100%;
      margin-top: 0.25rem;
      padding-top: 0.5rem;
      padding-bottom: 0.25rem;
      margin-bottom: 0.5rem;
      text-align: center;
      @extend .globerSemiBold;
      font-size: 12.8px;
      color: $SOLE-black;
      background-color: $SOLE-grey;
      &-final-sale {
        font-weight: bold;
        color: $SOLE-danger;
      }
      &-low-on-stock {
        font-weight: bold;
        color: $SOLE-blue;
      }
      &-next-eta {
        font-weight: bold;
        color: $SOLE-blue;
      }
      & .blue {
        color: $SOLE-blue;
      }
    }
  }
  &-subtotal {
    height: 14px;
    &-text,
    &-num {
      &--font {
        font-size: 14px;
        @extend .globerSemiBold;
      }
    }
  }
  & .free-shipping-red {
    color: red;
  }
  &-free-shipping-qualification {
    font-size: 14px;
    @extend .globerRegular;
    & a {
      color: $SOLE-blue;
      text-decoration: underline;
      @extend .globerBold;
      &:hover {
        color: $SOLE-black;
        text-decoration: underline;
      }
    }
  }
  &-price {
    &--font {
      font-size: 13px;
      @extend .globerRegular;
    }
  }
  &-shipping-notice {
    &--font {
      font-size: 13px;
      & > div {
        color: #000;
        font-weight: bold;
      }
    }
  }

  &-hr {
    &--color {
      border-top-color: $SOLE-grey !important;
    }
  }
  &-btn {
    &--font {
      font-size: 16px !important;
      line-height: 16px !important;
    }
    &--color {
      background-color: $SOLE-blue;
      &:hover {
        background-color: $SOLE-hover-blue;
      }
    }
    &--checkout {
      &.disabled {
        background-color: $SOLE-disabled;
      }
    }
  }
}

@include media-query($medium-up) {
  .Cart__overlay {
    width: unset;
    &-product {
      width: 350px;
      &-with-stock-message {
        width: 350px;
      }
    }
    &-position {
      position: fixed;
      z-index: 20001;
      top: 60.5px;
      right: 8px;
    }
    &-toggle-div {
      width: 400px;
    }
  }
  div.page-wrapper > div.Cart__overlay.Cart__overlay-position {
    right: 410px !important;
  }
}

@media (max-width: 599px) {
  .Cart__overlay {
    &-product {
      min-height: 110px;
      margin-right: 10px;
      width: 100%;
      &-with-stock-message {
        width: 100%;
      }
      &-image {
        min-height: 90px;
        min-width: 100px;
      }
    }
  }
}
