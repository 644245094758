#ysslider {
  .swiper-slide {
    position: relative;
    width: 100%;
    overflow: hidden;
    & .image-wrapper {
      width: 100%;
      height: 0;
      @media screen and (min-width: 992px) {
        padding-bottom: 36.5%;
      }
      @media screen
      and (min-width: 768px)
      and (max-width: 991.98px) {
        padding-bottom: 53.5%;
      }
      @media screen
      and (min-width: 576px)
      and (max-width: 767.98px) {
        width: auto;
        height: 100%;
        padding-bottom: 80%;
        & .loaded {
          top: -10%;
        }
      }
      @media screen
      and (max-width: 576px) {
        width: auto;
        height: 100%;
        padding-bottom: 107%;
      }
      padding-bottom: 86%;
      position: relative;
      & img {
        width: 100%;
        height: auto;
        position: absolute;
      }
    }

    &.Homepage__slider-image-top { background-position: top; }
    &.Homepage__slider-image-bottom { background-position: bottom; }
    &.Homepage__slider-image-center { background-position: center; }
    &.Homepage__slider-image-left { background-position: right; }
    &.Homepage__slider-image-right { background-position: left; }

    .centered {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
