.perfect-footbed {
  display: block;
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
  // margin-bottom: 50px;

  
  .question{
    a{
      text-align: left;
    }
  }
}

.closeWidgetDesktop{
  position: absolute;
  left: 10px;
  font-size: 12px;
  color: white;
  &:hover{
    color: white;
  }
}

.visible-xs-block{
  display: none;
}

.next{
  display: none;
}

.perfect-footbed-content {
  display: block;
  width: 85%;
  text-align: center;
  margin: 0 10% ;
  position: relative;
  top: 50%;
  // padding-bottom: 30px;
  transform: translateY(-50%);

  img{
    width: 100px;
    height: auto;

  }

  h3{
    font-size: 20px;
    margin: 15px auto ;
    padding: 15px 32px;
    text-transform: uppercase;
    font-family: GloberRegular,Arial,Helvetica,sans-serif;
    border-bottom: 1px solid black;
    display: inline-block;
  }

}

// .perfect-footbed .start {
//
// }

// .perfect-footbed .next {
//   display: block;
//   width: 150px;
//   height: 30px;
//   position: absolute;
//   bottom: 16px;
//   right: 20px;
// }

.perfect-footbed .next a {
  position: absolute;
  bottom: -58px;
  right: -30px;
  display: block;
  width: 150px;
  height: 30px;
  line-height: 31px;
  background-color: #58595b;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  font-weight: bold;
}

.perfect-footbed .start a {
  display: block;
  width: 250px;
  height: 30px;
  line-height: 31px;
  background-color: #58595b;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  font-weight: bold;
  margin: 0 auto;
}
.perfect-footbed .title{
  display: block;
  margin-bottom: 3px;
  text-transform: capitalize;
  font-size: 15px;
}

.question-one, .question-two, .question-four {
  display: inline-block;
  // width: 200px;
  // height: 30px;
  border: 1px solid #58595b;
  color: #58595b;
  text-align: center;
  padding: 8px 12px;
  margin: 5px;
  // line-height: 31px;
  font-weight: bold;
  letter-spacing: 1px;
}

.question-one:nth-child(2), .question-two:nth-child(2), .question-four:nth-child(2) {
  // margin-left: -4px;
}

// .question-three {
//   border: 1px solid #58595b;
//   display: block;
//   width: 100%;
//
//   padding: 11px 20px;
//   text-align: left;
// }



.question-three{
  span{
      line-height: 19px;
  }
  .title {
    display: block;
    letter-spacing: 2px;
    font-size: 16px;
    text-align: left;
    margin-bottom: 5px;;
  }

}

.question .active, .question .active span {
  background-color: #58595b;
  color: #fff;
}

.indicator {
  display: block;
  text-align: center;
  position: absolute;
  bottom: -65px;
  height: 30px;
  width: 100%;
}

.indicator li {
  list-style: none;
  display: inline-block;
}

.indicator li a {
  display:block;
  padding: 5px;
  margin: 0 3px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: solid 1px #58595b;
}
.indicator li .active {
  background: #58595b;
}

.question {

  a{
    text-align: left;
    padding: 10px 20px ;

    display: inline-block;
    // width: 200px;
    // height: 30px;
    border: 1px solid #58595b;
    color: #58595b;
    text-align: center;
    padding: 8px 12px;
    margin: 5px;
    // line-height: 31px;
    font-weight: bold;
    letter-spacing: 1px;
    span{
      line-height: 1.5;
      text-align: left;
    }
    &:hover{
      text-decoration: none;
    }
  }

  &.side_by_side{
    a{
      text-align: center;
      padding-right: 40px;
      padding-left: 40px;
      margin: 0;
      display: inline-block;
    }

  }

}

/* Tablet Styles */
@media only screen and (min-width: 600px) and (max-width: 959px) {

  .perfect-footbed-content{
    width: 100%;
    // top: 0%;
    // padding-bottom: 30px;
    // transform: translateY(0%);
    margin: 0;

    img{
      width: 100px;
    }

    h3 {
      margin: 6px 0;
    }


  }
  .fancybox-inner p {

    margin: 18px auto !important;
  }

  #perfect-footbed-third-slide{
    // margin-top: 30px;
  }

 #perfect-footbed-fourth-slide{
   .indicator{
    bottom: -10px;
   }
 }

.perfect-footbed{
  // height: auto;

  &.center-content{
    height: calc(100% - 30px);
    position: relative;
    margin-bottom: 50px;
    .perfect-footbed-content {
      top: 50%;
      transform: translateY(-50%);
    }

  }
}

.question a span {
  line-height: 1.3;
}

.fancybox-inner p{
  width: 100%;
}

.indicator {
  bottom: -30px;
}

  #perfect-footbed-second-slide{
    height: calc(100% - 30px);
    position: relative;
    margin-bottom: 50px;
    .perfect-footbed-content {

      top: 50%;

      transform: translateY(-50%);
    }
  }

}

/* Phone Styles */
@media only screen and (max-width: 599px) {

  .perfect-footbed-content {
    display: block;
    width: 80%;
    text-align: center;
    margin: 0 10%;
    position: relative;

  }


  .hidden-xs{
    display: none;
  }

  .visible-xs-block{
    display: inline-block;
  }

  .perfect-footbed-content{
    width: 100%;
    margin: 0 auto;
  }
  .perfect-footbed{
      .fancybox-inner h1{
        width: 100%;
      }
  }
  .fancybox-inner h1, .perfect-footbed .start a{
      width: 100%;
  }

  .question-one, .question-two, .question-four{
    width: 100%;
    margin: 5px 0;
    display: block;
    height: inherit;
    line-height: inherit;
    padding: 4px ;
  }

  .question-one:nth-child(2), .question-two:nth-child(2), .question-four:nth-child(2){
    margin-left: 0;
  }

  .perfect-footbed .next a{
    width: 100%;
    bottom: -9px;
    right: auto;
  }



  .fancybox-inner p span{
    font-size: 11px;
    line-height: 10px;
  }

  .indicator{
    position: relative;
    bottom: -25px;
    height: auto;
  }

.fancybox-inner p{
  line-height: 1.1;
}

.fancybox-inner h6{
  font-size: 12px;
  font-weight: bold;
  font: 11px/11px 'GloberBold', Arial, Helvetica, sans-serif;
}
  .perfect-footbed{

    .scaled-icon{
      width: 37px;
      height: auto;
    }

    p{
      font-size: 10px;
    }

    h3{
      font-size: 14px;
      margin: 8px 0;
    }

    .question{
      a{
        line-height: 1;
        text-align: left;
        letter-spacing: inherit;
        margin: 1px 0;
        span{
          font-size: 10px;
          line-height: 0.9;
          font-family: Verdana;
        }
        .title{
          line-height: 1;
          font-size: 13px;

        }
      }

        &.side_by_side{
          a{
            text-align: center;
            width: 90%;
          }

        }
    }

    p{
      width: 100%;
      margin: 12px 0 !important;
      line-height: 1.2
    }


  }

  #perfect-footbed-third-slide{
    height: inherit;
    .perfect-footbed-content{
      top: 0%;
      transform: translateY(0%);
      h3 {
        margin: 5px 0;
        padding: 5px 32px;
      }
      .question a {
        margin: 0;
        .title{
          font-size: 12px;
        }
      }

      p{
            margin: 8px 0 !important;
      }

      .question a span {
        font-size: 8px;
        line-height: 1.2
      }
    }
    .indicator {
      position: relative;
      bottom: -25px;
      height: auto;
    }
  }

  #perfect-footbed-fourth-slide{

    .perfect-footbed-content{

      h3 {
        margin: 5px 0;
        padding: 5px 32px;
      }
      .question a {

        .title{
          font-size: 12px;
        }
      }

      p{
            margin: 5px 0 !important;
            font-size: 10px
      }

      .question a span {
        font-size: 8px;
        line-height: 1.2
      }

      .question.side_by_side {
        a{
          width: 45%;
          display: inline-block
        }
      }

    }
    .indicator {
      position: relative;
      bottom: -25px;
      height: auto;
    }
    .show-results{
      bottom: -30px;
      position: relative;
      
    }
  }

}
