.modal{
  z-index: 99999;
  .modal-header {
    border-bottom: 0;
    text-align: center;
    position: relative;
    padding: 0;
    h4 { font-size: 28px; }
    button.close {
        top: -18px;
        right: -15px;
        position: absolute;
    }
  }
}
.modal-content {
  @include border-radius(0);
  padding: 40px 40px;
  p { display: block; }
}
.modal-content.perfect-footbed-modal-content {
  flex-direction: unset;
}
.modal-dialog { }
.modal-body {
  padding: 0 15px 15px  15px;
  .btn { margin:30px 0 0 0; }
  .modal--no-marging & {
    margin-left: $grid-gutter-width*0.5;
    margin-right: $grid-gutter-width*0.5;
  }
  a { text-decoration: underline; }
  small { font-size: 12px; }
  .thank-you-mailing-list {
    text-align: center;
    margin: 29% 0;
    h1 {
      font-size: 26px;
      margin-bottom: 20px;
      color: #000;
    }
    h2 {
      font: 22px/22px 'GloberSemiBold', Arial, Helvetica, sans-serif;
      line-height: 30px;
      margin-bottom: 20px;
      font-weight: 500;
      color: #000;
      text-transform: initial;
    }
    hr {
      width: 15%;
      position: relative;
      left: 43%;
      background-color: #0071d1;
      margin: 0 0 20px 0;
      border-top: solid 1px #0071d1;
    }
    div {

    }
    p {
      font-size: 17px;
      font-family: 'GloberRegular', Arial, Helvetica, sans-serif;
      padding: 0 0 20px 0;
      margin: 0;
      color: #000;
    }
  }
}
.modal-footer {
  display: initial;
  border: none;
  text-align: center;
}

// popup
.fade.in { background: rgba(0, 0, 0, 0.44); }
