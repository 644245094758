#corks-collected, .corks-collected_container {
  font-size: 12px;

  img {
    height: 22px;
    margin-right: 6px;
    vertical-align: middle;
  }
}

.swiper-main-header {
  margin-bottom: 5px;
  overflow: hidden;

  .swiper-slide {
    display: block;
    background: black;
  }
}
.main-header {
  background: black;
  overflow: hidden;
  padding: 30px ;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  margin-bottom: 3px;

  h1 {
    color: white;
    margin: 0 0 34px 0;
  }
  a.link {
    color: white;
    letter-spacing: 1px;
    display: block;
    font-family: 'GloberBold', Arial, Helvetica, sans-serif;
    padding-bottom: 1px;
    font-size: 14px;
    border-bottom: 2px solid white;
    display: inline-block;
    &:hover { text-decoration: none; }
  }
  &.text-right {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 50px 50px ;
  }
  &.text-left {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 50px 50px ;
  }
  &.text-right2 {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 70px ;
  }
  &.text-left2 {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 70px ;
  }
  & .btn-primary {
    color: black;
    background: $color-white;
    border: none;
    border-radius: 0px;
    font-family: 'GloberBold', Arial, Helvetica, sans-serif;
  }
}

.mini-header {
  @extend .main-header;
  height: 500px; //fallback: 
  height: 39vw;
  background: $color-contrast;
}

.sub-header {
  background: white;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 ;
  h3 {
    font-size: 25px;
    text-transform: inherit;
    margin-bottom: 25px;
    font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif;
    max-width: 500px;
    overflow: hidden;
    max-height: 100px;
    line-height: 1.4;
  }

  .btn-primary {
    background: black;
    color: $color-white;
    text-transform: capitalize;
    border: none;
    border-radius: 0px;
    font-family: 'GloberBold', Arial, Helvetica, sans-serif;
    padding-right: 20px;
    padding-left: 20px;
    line-height: 23px;
  }
}