.swiper-container, .swiper-container-initialized {

  /* Style for bullets as seen on homepage */
  .swiper-pagination-bullet {
    width: 83px;
    height: 5px;
    border-radius: 0;
    background: #fff;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
    opacity: 0.6
  }

  /* Style for prev and next arrow */
  .swiper-button {
    &-prev, &-next {
      &::after {
        font-size: 1rem;
        color: black;
      }
    }
  }
}
