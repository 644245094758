.alert-swiper {
  & .container {
    padding-left: 0;
    padding-right: 0;
    @media only screen and (min-width: 576px) {
      padding-left: unset;
      padding-right: unset;
    }
    & .swiper-slide-content {
      width: 80%;
    }
  }

  &.message {
    background: $grey-light;
    .col-xs-12 {
      position: relative;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 4px;
    }

    h6 {
      font-family: "Roboto", Arial, Helvetica, sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      padding-top: 2px;
      margin: 10px 0;
      span {
        text-transform: initial;
        margin-left: 0.25rem;
      }
      a {
        margin-left: 0.125rem;
      }
    }

    a {
      font-family: "Roboto", Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 0.875rem;
      color: $SOLE-black;
      padding: 0;
      margin: 0;
      margin-right: 0px;
      text-transform: initial;
      text-decoration: underline;
    }
  }
}
