& .loading {
  height: 100vh;
  width: 100vw;
  & h1 {
    margin-top: -150px;
    color: rgba(0, 0, 0, .3);
    font-size: 5rem;
    letter-spacing: 1rem;
    padding-left: 1rem;
    &:before {
      content: attr(data-text);
      position: absolute;
      overflow: hidden;
      max-width: 15rem;
      white-space: nowrap;
      color: #000;
      animation: loading 8s linear;
      letter-spacing: 1rem;
    }
  }
  @keyframes loading {
    0% {
      max-width: 0;
    }
  }
}