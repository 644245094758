@import 'partials/color';
.breadcrumbs{
  overflow: hidden;
  padding: 15px 0;
  margin-left: 10px;

  #collection & {
    margin-left:0px;
  }

  #product-list &{
    margin-left:0px;
  }

  &-back-btn{
    font-family: 'GloberBold', Arial, Helvetica, sans-serif;
    margin-right: 5px;

    &::before{
      top: 90%;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      content: "\e079";
      display: inline-block;
      vertical-align: text-top;
      font-size: 11px;
    }
    &::after{
      content: "";
      display: inline-block;
      border-right: 1px solid $color-dark-grey;
      height: 12px;
      margin-left: 9px;
    }
  }

  &-item{
    &::after{
      display: inline-block;
      content: '>';
      transform: scale(.5, 1);
      font-size: 20px;
      font-family: -webkit-body;
      margin-left: 2px;
      margin-right: 1px;

    }
    &--active{
      a, a:link{
        color: $color-link;
      }
    }
  }

  span{
    display: inline-block;
    padding: 0 16px;
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      list-style: none;
      display: inline-block;

      a, a:link{
        display: inline-block;
        padding: 3px 2px;
        color: black;
        text-decoration: none;
      }

      &.breadcrumbs-item--active{
        a{
          color: $color-link;
        }
      }
    }
  }
}
