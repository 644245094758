@import 'partials/color';

.modal-backdrop {
  .h1 { font: 16px/16px 'GloberRegular', Arial, Helvetica, sans-serif; width: 70%; margin: 18px auto !important; text-align: center; color: #000; letter-spacing: 2px; }
  hr { height: 1px; line-height: 1px; color: #000; background-color: #000; border: 0; width: 40%; margin: 0 auto !important; }
  p { width: 90%; margin: 24px auto !important; color: #000; line-height: 19px; font-size: 12px; }
  a { font-size: 12px; }
  h1 { font: 16px/16px 'GloberRegular', Arial, Helvetica, sans-serif; width: 70%; margin: 18px auto !important; text-align: center; color: #000; letter-spacing: 2px; }
  table { width: 90%; margin: 0 auto !important; font: 14px/14px 'GloberRegular', Arial, Helvetica, sans-serif; color: #000; table-layout: fixed; }
  table tr { height: 22px; }
  table tr.alt { background-color: #f1f1f1; }
  table tr th { font: 14px/14px 'GloberRegular', Arial, Helvetica, sans-serif; line-height: 22px; padding-top: 3px; }
  table tr td { text-align: center; line-height: 22px; border-left: 2px solid #939598; padding-top: 3px; }
  table tr td:nth-child(1) { border-left: none; }
  img { display: block; margin: 30px auto !important; width: 79px; height: 79px; }
  ul { font-size: 0; margin: -20px auto !important; width: 80%; }
  ul li { vertical-align: top; display: block; font-size: 12px; width: 100%; height: 140px; margin-top: -10px; }
  p { font-size: 12px; line-height: 18px; text-align: center; }
  p a { font-size: 12px; color: #000; text-decoration: underline; }
  p span.title { font: 12px/12px 'GloberBold', Arial, Helvetica, sans-serif; }
  p.social-media { line-height: 40px; height: 40px; font-size: 0; display: block; width: 256px; margin: 30px auto !important; }
  p.social-media span.title { font: 12px/12px 'GloberBold', Arial, Helvetica, sans-serif; line-height: 40px; }
  p.social-media a { display: inline-block; width: 64px; height: 30px; margin: 0; border-width: 1px 0 1px 1px; border-style: solid; border-color: #939598; }
  p.social-media a.right { border-right: 1px solid #939598; }
  p.social-media a img { display: block; width: 64px; height: 30px; margin: 0 !important; }
  @include media-query($small) { }
}

.carousel-item { background: #000; }
ys-white { color: white; }
ys-black { color: black; }

.accordion-sole {
  &_header {
    display: block;
    .text-right { @include transition(all,500ms, ease ); }
    & .active { @include rotate(90deg) }
  }
  &_content {
    display: none;
    &.active { display: block; }
  }
}

.text-scroll {
  padding: 10px 15px;
  border: solid 1px black;
}

.addfavority {
  position: absolute;
  width: 23px;
  top: 30px;
  right: 30px;
  font-size: 21px;
  z-index: 8;
  display: block;
  text-decoration: none;
  text-align: center;
  #Layer_1 { width: 25px; }
  .inner-addfavority{
    fill: rgba(255, 255, 255, 0.1);
    stroke: black;
    stroke-width:1.8;
    stroke-miterlimit:10;
  }

  &:hover {
    &::after {
      content: 'Save';
      display: block;
      font-size: 14px;
      text-align: center;
      font-weight: bolder;
      font-family: 'GloberBold', Arial, Helvetica, sans-serif;
    }
  }

  &:active, & .saved {
    .inner-addfavority { fill: rgb(0, 0, 0); }

    &::after {
      content: 'Saved';
      display: block;
      font-size: 14px;
      text-align: center;
      font-weight: bolder;
      font-family: 'GloberBold', Arial, Helvetica, sans-serif;
      
    }
  }
}

.dropdown {
  z-index: 99;
  padding: 0 10px;
  background: white;
  header {
    position: relative;
    display: block;
    overflow: hidden;
    &:hover { cursor: pointer; }
    span {
      position: absolute;
      top: 10px;
      right: 4px;
      font-size: 10px;
    }

    h5 {
      border-bottom: solid 1px;
      padding-right: 50px;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }
  }
  ul{
    display: none;
    list-style: none;
    &.show, &.active { display: block; }
    margin: 0 ;
    padding: 12px 0 0px  0;
    li {
      padding: 5px 0;
      margin: 0;
      &:hover, &.selected { color: $color-link; }
    }
  }
}

select.form-control {
  border: 1px solid $forms-dark;
  border-radius:0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  .nopadding & { margin-top: 0; }
}

#collections {
  .swiper-collections {
    transition: all .6s;
    .glyphicon.glyphicon-chevron-right, .glyphicon.glyphicon-chevron-left {
      opacity: 0;
      transition: all .6s;
    }

    &:hover {
      transform: translate(00px, -10px);
      .glyphicon.glyphicon-chevron-right, .glyphicon.glyphicon-chevron-left { opacity: 1; }
    }
  }
}

select:not([multiple]) {
  -webkit-appearance:none;
  -moz-appearance:none;
  background-position:right 50%;
  background-repeat:no-repeat;
  color: $forms-grey;
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 22.8 23.6' style='enable-background:new 0 0 22.8 23.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:%23424242;} %3C/style%3E%3Cpolygon class='st0' points='11.4,15.2 10.8,14.5 14.4,11.2 10.8,7.8 11.4,7.1 15.8,11.2 '/%3E%3C/svg%3E");
  padding: .5em;
  padding-right:1.5em;
  .product-details & { margin-top: 0; }
}

.hr-header {
  border-bottom: solid 1px $color-black;
  line-height: 1.5;
}

hr {
  border-top: solid 1px $color-black;
  &.hrLighter { border-top: 1px solid #eeeeee; }
}

.checkbox-inline {
  position: relative;
  padding-left: 25px;
  margin-right: 10px;
  margin: 5px 0px;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & + .checkbox-inline { margin-top: 5px; }
}

/* Hide the browser's default checkbox */
.checkbox-inline input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  border:1px solid black;
  background: white;
}

/* On mouse-over, add a grey background color */
.checkbox-inline:hover input ~ .checkmark { }

/* When the checkbox is checked, add a blue background */
.checkbox-inline input:checked + span{
  font-weight: bolder;
  font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif;
}
.checkbox-inline input:checked ~ .checkmark {
  background-color: #2196F3;
  border:1px solid #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-inline input:checked ~ .checkmark:after { display: block; }

.tip {
  position: relative;
  display: inline-block;
  vertical-align: top;
  img.icon { margin: 0; }

  &:hover {
    &:before {
      content: '';
      display: block;
      width: 0px;
      height: 0px;
      position: absolute;
      border-style: solid;
      border-width: 15px 15px 15px 0;
      border-color: transparent $color-slate transparent transparent;
      left: 110%;
      z-index: 9999;
    }
  }
  &:hover .tip-panel { visibility: visible; }

  .tip-panel {
    background: $color-slate;
    color: $color-white;
    padding: 15px 20px ;
    text-align: left;
    position: absolute;
    visibility: hidden;
    width: 250px;
    z-index: 999;
    left: 150%;
    transform: translate(0, -50%);
    p {
      font-size: 10px;
      margin: 0 0 5px 0;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-size: 10px;
        color: $color-white;
        margin: 0;
        &:before {
          content: '-';
          padding-right: 5px;
        }
      }
    }
    hr { color: white; }
    h4 { font-size: 14px; }
    h1, h2, h3, h4, h5 ,h6 { color: $color-white; }
  }
}

.message-panel {
  background: #dcdddb;
  overflow: hidden;
  padding: 15px 35px 25px 35px;
  margin: 0px 0 20px 0;
}

.text-diviser {
  position: relative;
  margin: 25px 0;

  span {
    display: inline-block;
    background: white;
    position: relative;
    padding: 0 20px;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    background: black;
    height: 1px;
    top: 50%;
    left: 0;
    position: absolute;
  }
}
