/* Colors */
$gray: #585856;
$blue: #0071d1;
$royal_blue: #306cd6;

.background-royalblue {
  background-color: #306cd6 !important;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#footbed-finder-modal-popup.YourSole {
  .FF {
    z-index: 10000;
    background-color: rgba(0,0,0,0.5);
    &__logo {
      &_title {
        padding-top: 10px;
      }
    }
    &__title {
      &_description {
        padding-top: 15px;
        div {
          line-height: 1.25;
        }
      }
    }

    &__description {
      &--size {
        line-height: 1.25;
      }
    }

    &__tips {
      line-height: 1.25;
      width: 95%;
    }

    &__progress--bar {
      label {
        width: 100%;
        text-align: left;
      }
    }

    &__size {
      border-color: #ccc !important;
      &.active {
        border-color: #0071d1 !important;
      }
    }

    &__button {
      &--link {
        color: black;
        font-size: 21px;
        width: 100%;
        border: 1px solid black;
        &:hover {
          color: white;
          border: 0;
        }
      }
      &--start {
        margin-top: 1em !important;
        .FF__button--link {
          border: 0;
          background-color: $SOLE-white;
          color: $SOLE-black;
        }
      }

      &--gender, &--injury_cond, &--metpad {
        a.btn.FF__button--link {
          margin-top: 0;
        }
      }

    }

    &__skip, &__next, &__finish {
      &--extra.btn {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    &__question {
      line-height: 1.25;
      text-align: center;
      &--style, &--shoe_type, &--shoe_fit, &--material {
        text-align: left;
      }
    }

    &__options {
      &_title {
        text-align: center;
        &--style, &--shoe_type, &--shoe_fit, &--material, &--arch_type {
          text-align: left;
        }
      }
      &--style {
        width: 92%;
      }
    }

    .modal-content {
      padding: 0 !important;
    }

    .modal {
      &-content {
        padding: 0 !important;
      }

      &-header {
        button.close {
          top: 15px;
          right: 15px;
          z-index: 1;
        }
      }
    }

    .custom-control-description {
      font-size: 1rem;
      line-height: 1.25;
    }
  }

  .progress {
    height: 3px !important;
  }
}

.FF {
  strong { font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif; }
  &__footbed-finder-special-btn {
    display: inline-block;
    width: 24%;
    height: 30px;
    line-height: 1.25;
    text-align: center;
    vertical-align: top;
    font-size: 14px;
    color: white !important;
  }
  color: #000000;
  z-index: 12347;
  height: auto;
  span {
    line-height: 1.25 !important;
  }
  &__blue {
    @extend .background-royalblue;
  }
  &__text-black {
    font-weight: bold;
    color: black;
  }
  &__text-blue {
    font-weight: bold;
    color: $royal_blue;
  }
  &__black {
    background: #000000 !important;
  }
  .close-x {
    height: 20px;
    width: 20px;
  }
  .modal-dialog {
    display: flex;
    justify-content: center;
  }
  .modal-content {
    width: 96vw;
    max-width: 400px;
    max-height: 640px;
    min-height: 640px;
    height: 96vh;
    border-radius: 0;
    border: 0;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    .modal-header {
      border: 0;
      padding-bottom: 0px;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-bottom: 0px;
      padding-top: 0px;
    }
    .modal-footer {
      display: flex;
      flex-direction: column;
      border: 0;
      padding-top: 0px;
      .FF__controls {
        display: flex;
        flex-direction: row;
        border: 0;
        justify-content: flex-end;
        width: 100%;
        height: auto;
        .FF__prev, .FF__next, .FF__skip, .FF__finish {
          align-items: center;
          justify-content: center;
          color: $SOLE-white;
          font-weight: bold;
          border-radius: 0;
          display: flex;
          min-width: unset;
          width: 90px;
          height: 30px;
          font-size: 0.8rem;
          background-color: $gray !important;
          border: 0px !important;
        }
        .FF__next, .FF__finish {
          @extend .background-royalblue;
          background-color: #306cd6 !important;
        }
        .FF__prev {
          margin-right: auto;
        }
      }
    }
  }
  &__container {
    margin: 0 auto;
    &--shoe_type, &--shoe_fit, &--material, &--style { width: 80%; }
  }
  &__button {
    &--link {
      color: black;
      font-size: 21px;
      width: 100%;
      border: 1px solid black;
      &:hover {
        color: white;
        border: 0;
      }
    }
    &--start {
      margin-top: 1em !important;
      .FF__button--link { border: 0; &:hover { background-color: black; }}
    }
  }
  &__button--end {
    .FF__button--link {
      background: $blue !important;
      color: white !important;
      border-radius: 0;
      border: 0 !important;
      &:hover {
        background: black !important;
        color: white !important;
      }
    }
  }
  &__progress {
    &--bar {
      width: 100%;
      & label {
        @extend .globerRegular;
        font-size: 12px;
        margin-left: 2.5%;
        margin-bottom: 0.25rem;
      }
    }
    position: relative;
    overflow: visible;
    margin-bottom: 20px;
    width: 95%;
    margin-left: 2.5%;
    height: 2px;
    .progress-bar {
      box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
      position: absolute;
      background-color: #007bff;
      height: 3px;
      top: -0.5px;
      left: 0;
      border-radius: 3px;
    }
  }
}

.FF {
  &__button--end {
    .FF__button--link {
      border-radius: 0;
      background: $royal_blue !important;
      color: white !important;
      padding-left: 4rem !important;
      padding-right: 4rem !important;
      font-size: 20px;
      margin-top: 10px;
      border: 0;
      &:hover {
        background: black !important;
        color: white !important;
      }
      &.active {
        font-weight: 700 !important;
      }
    }
  }
  input[type=radio]{
    position: absolute;
    visibility: hidden;
  }
  &__title {
    &, &_1, &_2 {
      font-weight: bold;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      font-size: 21px;
      &_description {
        @extend .globerRegular;
        font-size: 21px;
        line-height: 1.25;
      }
    }
    &_1 {
      &--end {
        line-height: 1.25;
        text-align: center;
      }
    }
  }
  &__info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:nth-child(1) {
      img {
        height: 60px;
      }
    }
    &:nth-child(3) {
      img {
        height: 50px;
      }
      img ~ img {
        padding-left: 25px;
      }
    }
    &--logo {
      display: flex;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;
      img {
        height: 80px;
      }
    }
    &--description {
      text-align: center;
      font-size: 13.5px;
      margin-bottom: 15px;
      width: 250px;
      line-height: 1.25;
    }
  }
  &__logo {
    img {
      height: 80px;
    }
    &_title {
      @extend .globerBold;
      margin-bottom: 1rem;
      font-size: 33px;
    }
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    &--start {
      img {
        max-height: unset !important;
        max-width: unset !important;
      }
    }
    &--size {
      margin-bottom: 1rem !important;
    }
    &--end {
      img {
        height: 90px !important;
        max-height: unset !important;
        max-width: unset !important;
      }
    }
    &--arch_type {
      margin-bottom: 1.5rem;
      img {
        height: 70px;
        max-width: unset !important;
        max-height: unset !important;
      }
    }
  }
  &__question {
    font-size: 20px;
    font-weight: 700;
    &--metpad {
      font-size: 16px;
    }
    &--gender {
      margin-bottom: 1rem;
    }
    &--injury_cond {
      margin-bottom: 1rem;
      text-align: center;
    }
    &--info {
      margin-bottom: 1rem;
    }
    &--size {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
  &__button--link {
    @extend .globerSemiBold;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    font-size: 21px;
    background: white;
    color: black;
    border-radius: 0;
    &:hover {
      background: $blue;
      color: white;
    }
    &.active {
      @extend .globerSemiBold;
      font-weight: 700 !important;
    }
  }
  &__button, &__buttons_1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    &--gender, &--injury_cond, &--metpad {
      box-sizing: border-box;
      a {
        width: 100%;
        border: black solid 1px;
        &:hover {
          @extend .background-royalblue;
          color: white !important;
          border: $blue solid 1px;
        }
        &.active {
          @extend .background-royalblue;
          color: white !important;
          border: $blue solid 1px;
        }
      }
      .btn {
        margin: 0px;
      }
    }
    &--injury_cond, &--metpad {
      .FF__button--link {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
        border-radius: 0;
      }
    }
  }
  &__tips {
    font-size: 12px;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    span {
      line-height: 1.25;
    }
    &--shoe_type, &--shoe_fit {
      padding-left: 0rem;
      padding-right: 0rem;
      margin-bottom: 1.5rem;
    }
    &--size {
      font-size: 12px;
    }
  }
  &__options {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // margin: -0.5%;
    & .FF__radio label,
    & .FF__radio input {
      cursor: pointer;
    }
    &--style {
      flex-wrap: nowrap !important;
      .FF__radio {
        width: 100%;
      }
      .custom-control-description {
        font-size: 10px;
      }
    }
    &--size {
      flex-direction: row !important;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
    &--injury_type {
      height: 300px;
      .FF__radio {
        width: 50%;
        .custom-control-label {
          padding-left: 8px;
        }
      }
    }
    &--shoe_type_container, &--shoe_fit_container, &--arch_type {
      display: flex;
      justify-content: center;
      & .FF__radio {
        margin-bottom: 10px;
      }
    }
    &_title {
      font-weight: bold;
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      font-size: 15px;
      color: #4e4d4d;
    }
  }
  &__radio {
    margin-top: 0.5rem;
    margin-bottom: 10px;
    .custom-control-input:checked ~ .custom-control-label::before {
      @extend .background-royalblue;
      border: $royal_blue solid 1px;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: unset !important;
    }

    .custom-control-input, .custom-control-input:focus, .custom-control-input:active {
      outline: none;
    }
    .custom-control-label {
      font-weight: bold;
      font-size: 15px;
      color: black;
      padding-left: 20px;
      padding-top: 5px;
      line-height: 1.25;
      &::before {
        width: 20px;
        height: 20px;
        top: 4px;
        left: -20px;
        border-radius: 0% !important;
        background: white;
        border: black solid 1px;
      }
      &:after {
        width: 20px;
        height: 20px;
        top: 4px;
        left: -20px;
      }
    }
    .custom-control-description {
      font-size: 14px;
      padding-left: 20px;
    }

    &__description {
      font-weight: bold;
      color: #4c4c4c;
      font-size: 13px;
      span {
        line-height: 1.25;
      }
      &_1, &_2 {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        font-weight: bold;
        span {
          line-height: 1.25;
        }
        padding-bottom: 15px;
        text-align: center;
        font-size: 14px;
        line-height: 1.25;
      }
    }
  }
  &__size {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    flex: 0 0 24%;
    margin: 0.5%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    height: 2.5rem;
    cursor: pointer;
    border: solid 1px $gray !important;
    background: $white !important;
    padding: 0rem 0rem 0rem 0rem !important;
    padding-top: 0.6rem !important;
    padding-bottom: 0.5rem !important;
    font-size: 13px;
    line-height: 1.25 !important;
    &:hover {
      border: solid 1px $black !important;
    }
    &.active {
      border: solid 5px $blue !important;
      background: $blue !important;
      color: white;
    }
    &--not-available {
      color: $gray;
      pointer-events: none;
    }
  }
  &__description {
    font-weight: bold;
    color: #4c4c4c;
    font-size: 14px;
    &_1, &_2 {
      padding-bottom: 1rem;
      text-align: center;
      font-size: 14px;
      line-height: 1.25;
    }
    &_1 {
      &--empty_filter {
        margin-top: 3rem;
        margin-bottom: 3rem;
        & div {
          margin-bottom: 0.5rem;
        }
      }
    }
    &--end {
      text-align: center;
      font-weight: 400 !important;
      margin-bottom: 15px;
    }
    &--size {
      width: 80%;
      text-align: center;
    }
  }
}

@media (min-width: 585px) and (min-height: 850px) {
  .FF {
    .close-x {
      height: 20px;
      width: 20px;
    }
    .modal-content {
      max-width: unset;
      width: 585px;
      min-width: 585px;
      max-height: unset;
      min-height: unset;
      height: 850px;
      .modal-body {
        padding-left: 4.5rem;
        padding-right: 4.5rem;
      }
      .modal-footer {
        .FF__controls {
          width: 100%;
          height: auto;
          .FF__prev,
          .FF__next,
          .FF__skip,
          .FF__finish {
            display: flex;
            min-width: unset;
            width: 90px;
            height: 30px;
            font-size: 0.8rem;
          }
          .FF__prev {
            margin-right: auto;
          }
        }
      }
    }
    &__progress {
      &--bar {
        margin-bottom: 2rem;
        width: 100%;
        & label {
          font-size: 14px;
          margin-left: 2.5%;
          margin-bottom: 0.25rem;
        }
      }
      margin-bottom: 20px;
      width: 95%;
      margin-left: 2.5%;
      height: 2px;
      .progress-bar {
        height: 3px;
      }
    }
  }
  .FF {
    &__button--end {
      .FF__button--link {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
        font-size: 24px;
        margin-top: 10px;
        border-radius: 0;
        border: 0;
      }
    }
    &__title {
      &--empty_filter {
        text-align: center;
      }
      &,
      &_1,
      &_2 {
        font-size: 25px;
        &_description {
          font-size: 25px;
          line-height: 1.25;
        }
      }
      &_1 {
        &--end {
          line-height:1.25;
        }
      }
    }
    &__info-box {
      &:nth-child(1) {
        img {
          height: 60px;
        }
      }
      &:nth-child(3) {
        img {
          height: 50px;
        }
        img~img {
          padding-left: 25px;
        }
      }
      &--logo {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        img {
          height: 90px;
        }
      }
      &--description {
        font-size: 16px;
        margin-bottom: 25px;
        width: 250px;
        line-height: 1.25;
      }
    }
    &__logo {
      margin-top: 15px;
      img {
        height: 90px;
      }
      &_title {
        font-size: 40px;
        margin-bottom: 1rem;
      }
      &--start {
        img {
          height: 52px !important;
          max-height: unset !important;
          max-width: unset !important;
        }
      }
      &--end {
        margin-top: 0.5rem;
        img {
          height: 100px !important;
          max-height: unset !important;
          max-width: unset !important;
        }
      }
      &--size {
        margin-bottom: 2rem !important;
      }
      &--arch_type {
        img {
          max-width: unset !important;
          max-height: unset !important;
        }
      }
    }

    &__question {
      font-size: 25px;
      &--metpad {
        font-size: 20px;
      }
      &--injury_cond {
        text-align: left;
        width: 75%;
      }
      &--info {
        margin-bottom: 2rem;
      }
      &--size {
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }

    &__button--link {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      font-size: 24px;
      border-radius: 0;
    }

    &__button,
    &__buttons_1 {
      &--gender,
      &--injury_cond,
      &--metpad {
        width: 75%;
        a {
          width: 100%;
        }
        .btn {
          margin: 0px;
        }
      }
      &--injury_cond, &--metpad {
        .FF__button--link {
          padding-left: 2rem !important;
          padding-right: 2rem !important;
          border-radius: 0;
        }
      }
    }

    &__tips {
      font-size: 17px;
      margin-top: 1rem;
      margin-bottom: 1.25rem;
      padding-left: 1rem;
      padding-right: 1rem;
      &--shoe_type, &--shoe_fit {
        margin-bottom: 2.5rem;
      }
      &--size {
        font-size: 16px;
      }
    }
    &__options {
      margin-left: 1em;
      margin: -0.25rem;
      &--size {
        flex-direction: row !important;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
      &--injury_type {
        height: 340px;
      }
      &--shoe_type_container,
      &--shoe_fit_container,
      &--arch_type {
        & .FF__radio {
          margin-bottom: 10px;
        }
      }
      &_title {
        font-size: 18px;
      }
    }

    &__radio {
      margin-bottom: 10px;
      .custom-control-input,
      .custom-control-input:focus,
      .custom-control-input:active {
        outline: none;
      }
      .custom-control-label {
        font-size: 20px;
        padding-left: 20px;
        padding-top: 4px;
        line-height: 1.25;
        &::before {
          width: 20px;
          height: 20px;
        }
        &::after {
          width: 20px;
          height: 20px;
        }
      }
      .custom-control-description {
        font-size: 14px;
        padding-left: 20px;
        
      }

      &__description {
        font-size: 16px;
        &_1,
        &_2 {
          padding-bottom: 15px;
          font-size: 14px;
        }
      }
    }

    &__size {
      height: 2.8rem;
      cursor: pointer;
      font-size: 16px;
      line-height: 1.25 !important;
      &--not-available {
        pointer-events: none;
      }
    }
    &__description {
      font-size: 18px;
      &_1,
      &_2 {
        padding-bottom: 1rem;
        font-size: 18px;
        line-height: 1.25;
      }
      &--end {
        margin-bottom: 15px;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 500px) {
  .FF {
    .modal-content {
      min-height: unset !important;
      height: 83vh;

      .modal-body {
        padding-left: 1em;
        padding-right: 1em;
      }
    }

    &__description {
      &--size {
        width: 100%;
      }
    }

    &__logo {
      img {
        height: 40px;
        margin-top: 10px;
      }
    }

    &__tips {
      width: 100% !important;
      padding: 0;
    }

    &__question {
      font-size: 18px; 
      &--info {
        margin: 1em;
      }
    }

    &__info-box{
      :nth-child(1) {
        img {
          height: 50px;
        }
      }

      :nth-child(3) {
        img {
          height: 45px;
        }
      }

      &--logo {
        img {
          height: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) and (max-height: 670px) {
  .FF {
    .modal-content {
      height: 85vh !important;
    }
  }
}

@media screen and (max-width: 500px) and (max-height: 630px) {
  .FF {
    .modal-content {
      height: 565px !important;
      top: -30px;
    }

    &__controls {
      .FF {
        &__prev, &__skip, &__next, &__finish {
          margin-top: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .FF {
    .modal-content {
      .modal-content {
        .FF__controls {
          .FF__prev {
            margin-left: -10px;
          }
        }
      }
    }
    &__buttons_1{
      &--injury_cond .FF__button--link {
        padding-left: 2em !important;
        padding-right: 2em !important;
      }
    }

    &__buttons_1 {
      &--metpad {
        .FF__button--link {
          padding-left: 2em !important;
        padding-right: 2em !important;
        }
      } 
    }
  }
}

#select-a-size-modal-popup {
  .FF {
    &__button--size {
      .FF__button--link {
        background-color: $blue;
        color: white;
        &:hover {
          background-color: black;
        }
      }
    }
  }
}

@media screen and (max-height: 600px) {
  .FF {
    .modal-content {
      min-height: unset;
    }
    &__size {
      height: 1.75rem;
    }
    &__logo {
      img {
        height: 50px;
      }
    }
    &__title {
      margin-top: unset !important;
    }
    &__info-box {
      &--description {
        width: 300px;
      }
      &:nth-child(1) {
        img {
          height: 50px;
        }
      }
      &--logo {
        img {
          height: 70px;
        }
      }
      &:nth-child(3) {
        img {
          height: 40px;
        }
      }
    }
  }
}