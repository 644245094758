#footer-sole{
  @media only screen and (min-width: 992px) {
    position: relative;
    z-index: 14;
  }
  background: black;
  color: $color-white;
  overflow: hidden;
  padding: 40px 10px;
  @include media-query($medium-up) { padding: 40px 0px 0px 0px; }
  width: 100%;
  p, a { color: $color-white; }
  .btn { text-transform: capitalize; }

  ul, li {
    padding: 8px 0;
    margin: 0;
    list-style: none;
    font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: $color-white;
    a { font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif; }
  }
  ul {
    margin-top: 18px;
    margin-left: 0px;
    @include media-query($medium-up) { margin-left: 20px; }
  }
  input[type=submit] {
    height: inherit;
    line-height: inherit;
  }

  .footer-col-4 { text-align: center; }

  .footer-logos {
    padding-top: 40px;
    @include media-query($medium-up) { padding-bottom: 0; }

    a {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      @include media-query($medium-up) { }
      &:hover { text-decoration: underline; }
      &.recork { @include media-query($medium-up) { order: 12 } }
    }

    img {
      height: 42px;
      width: auto;
      margin-bottom: 60px;
      
      @include media-query($medium-up) {
        height: 40px;
        margin-bottom: 20px;
      }
    }
  }

  input[type="text"], input[type="email"] {
    width: 100%;
    color: grey;
    line-height: 31px;
    padding-left:10px;
    font-size: $font-base;
  }

  .btn-default {
    background: black;
    border: 2px solid white;
    border-radius: 0;
    color: $color-white;
  }

  p {
    font-size: 14px;
    font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    padding-bottom: 8px;
    line-height: 18px;
    white-space: normal;
  }
}

.footer-credits {
  font-size: 11px;
  line-height: 20px;
  padding-top: 20px;

  @include media-query($medium-up) { padding-top: 100px; }
 
  a, p {
    color: $color-white;
    font-size: 11px;
    line-height: 20px;
  }
  p {
    display: inline-block;
    margin: 25px 0;
  }

  a { padding: 0 8px; }

  &-logo {
    width: 83px; 
    display: inline-block;
    margin-right:15px;
    vertical-align: middle;
    margin-bottom: 2px;
    @include media-query($medium-up) { margin-right: 15px; }
  }
 
  &-content {
    &--brand {
      margin-left: -$grid-gutter-width*0.5;
      @include media-query($medium-up) { order: 1; }
    }

    &--payment {
      height: 30px;
      @include media-query($medium-up) {
        padding-right: $grid-gutter-width * 2;
        text-align:right;
        order: 3;
      }
    }
    &--extra {
      @include media-query($medium-up) {
        text-align:left;
        order: 2;
      }
      @include media-query($large-up) { padding-left: $grid-gutter-width * 2; }
    }
  }
}
