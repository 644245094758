@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto+Condensed:ital,wght@0,700;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "partials/color";

// Share styling between main and top nav
.sole-mainnav,
.sidebar-nav {
  .nav-link,
  .dropdown-item,
  .sidebar-toggle,
  .card,
  .card .small {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
  }

  .dropdown-item {
    font-size: 1rem;
    line-height: 20px;
  }

  .badge { font-size: 62.5% }
  .badge.badge-pill {
    border-radius: 0.25rem;
    &.badge-primary {
      background-color: $SOLE-blue !important;
    }
  }

  .signup {
    &.text-decoration-underline {
      text-decoration: underline;
    }
    color: $SOLE-black;
  }

  & .font-size-1125 {
    font-size: 1.125rem;
  }

  .cart-activation {
    position: relative;
    &.display-counter {
      &::after {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
    &::after {
      display: none !important;
      counter-reset: items var(--items);
      position: absolute;
      min-width: 1rem;
      height: 1rem;
      text-align: center;
      width: fit-content;
      border-radius: 1rem;
      background: $SOLE-danger;
      font-size: 0.75rem;
      color: $SOLE-white;
      content: counter(items);
      padding: 0.25rem;
      z-index: 2;
    }
  }

  .font-weight-semibold {
    font-weight: 500 !important;
  }
}

.sole-mainnav, .sidebar-nav {
  .cart-activation::after {
    top: calc(50% - 20px);
    right: calc(50% - 20px);
  }
}

.sidebar-nav, .sole-mainnav {
  & img.original-icon-image {
    display: block;
  }
  & img.alt-icon-image {
    display: none;
  }
}

.logged-in {
  .sidebar-nav, .sole-mainnav {
    .account-user-icon {
      & img.original-icon-image {
        display: none;
      }
      & img.alt-icon-image {
        display: block;
      }
    }
  }
  .original-copy {
    display: none !important;
  }
  .alternate-copy {
    display: block !important;
  }
}

.display-counter {
  & img.original-icon-image {
    display: none;
  }
  & img.alt-icon-image {
    display: block;
  }
}

.sole-mainnav {
  &.bg-dark,
  &.navbar-dark,
  .bg-dark {
    background-color: $SOLE-black !important;
  }
}

// Main Navigation
.sole-mainnav {
  transition: margin-top 0.2s;
  top: 0;
  left: 0;
  z-index: 20;
  margin-top: -60.5px;
  width: 100vw;
  &.show {
    margin-top: 0px;
  }

  #SOLElogo {
    width: 85px;
    height: 15.77px;
    @include media-query($medium-up) {
      width: 110px;
      height: 20.41px;
    }
  }

  .nav-link {
    color: $SOLE-white !important;
    display: inline-block;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 1rem;
    position: relative;
  }

  .nav-item.dropdown {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .mid-nav {
    .dropdown-menu.show ~ .nav-link:after,
    .dropdown-menu ~ .nav-link:hover:after,
    .dropdown-menu:hover ~ .nav-link:after,
    .nav-item:hover .nav-link:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 0.5rem;
      bottom: 0;
      left: 0;
      background: $SOLE-white;
    }
  }

  .dropdown-menu {
    text-align: left;
    max-height: 90vh;
    background-color: $SOLE-white;

    &.shadow-bottom {
      box-shadow: 0 2rem 2rem -2rem rgba(0, 0, 0, 0.34);
    }

    & div.row {
      max-width: 1140px;
    }

    .dropdown-item {
      color: $SOLE-black;
      background-color: unset;
      &[href]:hover {
        background-color: unset;
        color: $SOLE-blue;
      }
    }
  }

  .mainnav-border-line {
    border-color: $SOLE-border-grey !important;
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .nav-notice.card {
    & small {
      font-size: 75%;
      line-height: 1rem;
    }
  }

  & .mid-nav .nav-item {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    & a.nav-link {
      line-height: 20px !important;
    }
  }
}

// Size bar
#sidebar {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;

  .sidebar-nav {
    z-index: 4;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.34);
  }

  &.show .sidebar-nav {
    margin-right: 0px;
  }

  & .nav-link {
    margin: 0rem;
    padding: 0.75rem;
    width: 100%;
  }

  & .nav-divider {
    border-top: 4px solid $SOLE-grey;
  }

  .rem-square {
    height: 2rem;
    width: 2rem;
  }

  & .sidebar-nav {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 70vw;
    min-width: 300px;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 375px;
    background: $SOLE-white;
    overflow-y: scroll;
    margin-right: -100vw;
    transition: all 0.2s;
  }

  .collapse.show ~ .btn .fa-plus,
  .collapsing ~ .btn .fa-plus {
    display: none !important;
  }

  .collapse.show ~ .btn .fa-minus,
  .collapsing ~ .btn .fa-minus {
    display: block !important;
  }

  .nav-item {
    border: 1px solid $SOLE-white !important;
    &.border-bottom {
      border-bottom: 1px solid $SOLE-border-grey !important;
    }
    & .btn.btn-link {
      border: 1px solid $SOLE-white !important;
      & span.nav-line-height {
        line-height: 27px;
      }
    }
  }
}

html.prevent-scrolling-on-sidebar-open,
html.prevent-scrolling-on-sidebar-open body {
  overflow: hidden !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  #sidebar .overlay {
    display: block;
  }
}
