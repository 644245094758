@import '~NodeModules/animate.css/source/_vars.css';
@import '~NodeModules/animate.css/source/_base.css';
@import '~NodeModules/animate.css/source/fading_entrances/fadeInUp.css';

#create-account-pop-up{
  .form-control{
    margin-top: -5px;
    margin-bottom: 20px;
  }
  .small{
    font-size: 12px;
  }
}
@keyframes mymove {
  from {bottom: -100px;
        }
  to {bottom: 55px;}
}

.Mailing_popup-close--js:hover {
  cursor: pointer;
}

#mailing-list-popup {
  padding-right: 0 !important;
  &.ysdotcom {
    .modal-header {
      button.close {
      top: 16px;
      right: 16px;
      padding: 0.5rem 0.75rem 0.25rem;
      span { color: white; font-family: Arial; }
      }
    }
    .modal-body { 
      margin-top: 30px !important; 
      .modal-title {
        text-transform: uppercase;
      }
      .description2 strong { font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif; }
    }
    .thank-you-mailing-list {
      margin: 29% 0;
      h1 {
        font-size: 26px;
        margin-bottom: 20px;
        color: #000;
      }

      h2 {
        font: 22px/22px 'GloberSemiBold', Arial, Helvetica, sans-serif;
        line-height: 30px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #000;
        text-transform: initial;
        letter-spacing: 0;
      }
      hr {
        width: 15%;
        position: relative;
        left: 43%;
        background-color: #0071d1;
        margin: 0 0 20px 0;
        border-top: solid 1px #0071d1;
      }
      p {
        font-size: 17px;
        font-family: 'GloberRegular', Arial, Helvetica, sans-serif;
        padding: 0 0 20px 0;
        .small { line-height: 1.5; }
      }
      div { font-size: 14px; line-height: 1.5; }
    }
  }
  .modal-header {
    button.close {
      top: -40px;
      right: 12px;
      position: absolute;
      color: white;
      background: black;
      opacity: 1;
      padding: 0.35rem 0.75rem 0.35rem;
      span { font-size: 36px; }
    }
  }
  &.modal {
    color: black;
    position: fixed;
    z-index: 20;
    top: unset;
    right: unset;
    bottom: 30px;
    left: 50px;
    width: 340px;
    & .modal-dialog {
      & .modal-header {
        margin-left: 1rem;
        margin-top: 1rem;
        & img{
          position: absolute;
          right: 15px;
          top: 0px;
        }
      }
      & .modal-body {
        margin: 0rem 1rem 1.25rem 1rem;
        padding: 0;
        & .modal-title {
          text-align: left;
          font-size: 24px;
          line-height: 1.5em;
          font-family: 'GloberRegular', Arial, Helvetica, sans-serif;
          strong {
            display: inline;
            font-family: 'GloberBold', Arial, Helvetica, sans-serif;
          }
        }

        .background-blue {
          background-color: #0071d1;

        }
        p {
          margin: 10px 0 10px 0;
        }
        form {
          text-align: left;
          p.description {
            font-size: 20px;
            font-family: 'GloberRegular', Arial, Helvetica, sans-serif;
            line-height: 1;
            word-break: normal;
          }
          p.description2 {
            font-size: 14px;
            font-family: 'GloberRegular', Arial, Helvetica, sans-serif;
            line-height: 1.5em;
            word-break: normal;
            width: 90%;
            margin: 10px auto;
          }
          p.details {
            font-size: 12px;
            font-family: 'GloberRegular', Arial, Helvetica, sans-serif;
            line-height: 19px;
            word-break: normal;
          }
          div.links {
            margin-top: 25px;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            a {
              color: #000;
              text-decoration: underline;
              font-size: 12px;
              &:hover { color: #0071d1; }
            }
          }
          p.lists {
            display: flex;
            justify-content: space-between;
            span.list-item {
              display: block;
              position: relative;
              margin: 5px 0;
              input[type="radio"].required:invalid + label:before {
                border: 1px solid red;
              }
              input[type="radio"] {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
              }
              label {
                padding-left: 30px;
                position: relative;
                color: black;
                font-size: 12px;
              }
              label:before {
                content: "";
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 0;
                border: 1px solid #000;
              }
              input[type="radio"]:checked + label:before {
                background-color: #0071d1;
              }
            }
          }
          label[for="email"] { font-family: "GloberSemiBold", Arial, Helvetica, sans-serif; }
          input[type=email] {
            width: 100%;
            border: 1px solid #000;
            height: auto;
            line-height: 1.25;
            font-size: 14px;
            padding: 10px;
            -webkit-appearance: none;
          }
          input[type=submit] {
            display: block;
            width: 100%;
            background-color: #0071d1;
            color: #fff;
            font-size: 21px;
            border: 0;
            padding: 13px 0 10px;
            font-family: 'GloberBold', Arial, Helvetica, sans-serif;
            height: auto;
          }
          input[type=submit]:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

// iPad Pro
@media screen and (max-width: 1068px) and (min-height: 1366px){
  #mailing-list-popup {
    right: unset;
    top: 30%;
    left: 25% !important;
    height: 500px;
    width: 500px;
  }
}

// iPad
@media screen and (max-width: 768px) and (min-height: 1024px) {
  #mailing-list-popup {
    // right: unset;
    // top: 25%;
    // left: 30% !important;
    // height: 525px;
    // width: 330px;
  }
}

@media screen and (max-width: 450px) {
  #mailing-list-popup {
    right: unset;
    top: 10%;
    left: 10% !important;
    height: auto;
    overflow: auto;
  }
}

@media screen and (max-width: 375px) {
  #mailing-list-popup { left: 5% !important; }
}

@media screen and (max-width: 335px) {
  #mailing-list-popup {
    top: 5%;
    left: 5% !important;
    &.modal {
      width: 300px;
      height: 500px;
      p.lists { 
        flex-wrap: nowrap;
        span.list-item label { font-size: 10px !important; } 
      }
    }

  }
}

@media screen and (max-width: 320px) {
  #mailing-list-popup {
    left: 3% !important;
    width: 300px;

    form {
      p.links {
        display: flex;
        flex-direction: column;

        a {
          display: block;
          margin: 0 auto 20px;
          text-align: center;
        }

        a:nth-of-child(2) {
          margin-left: auto;
        }
      }
    }
  }
}

@media screen and (max-height: 600px) {
  #mailing-list-popup {
    top: 5%;
    height: auto;
    overflow: auto;
  }
}

@media screen and (max-height: 500px) {
  #mailing-list-popup {
    width: 440px;
    height: 415px;
    p.lists {
      display: flex !important;
      justify-content: space-between;

      span.list-item label {
        padding-left: 25px;
      }
    }

    form p.links a:nth-child(2) {
      margin-left: 140px;
    }
  }
}

@media screen and (max-height: 400px) {
  #mailing-list-popup {
    height: 350px;
    p {
      margin: 5px 0;
    }

    form input[type=email] {
      margin: 10px 0;
    }

    form {
      p.lists {
        margin: 0 0 10px;
      }

      p.links {
        margin-top: 10px;

        a:nth-child(2) {
          margin-left: 140px;
        }
      }
    }
  }
}

@media screen and (max-height: 320px) {
  #mailing-list-popup {
    p {
      margin: 0;
    }
    form {
      .head {
        font-size: 18px;
      }

      input [type=email] {
        margin: 5px 0;
      }

      p.lists {
        margin: 0 0 5px;
      }

      p.links {
        margin-top: 5px;
      }
    }
  }
}

@media screen and (max-height: 500px) {
  #mailing-list-popup {
    width: 440px;
    height: 415px;
    p.lists {
      display: flex !important;
      justify-content: space-between;

      span.list-item label {
        padding-left: 25px;
      }
    }

    form p.links a:nth-child(2) {
      margin-left: 140px;
    }
  }
}

@media screen and (max-height: 400px) {
  #mailing-list-popup {
    height: 350px;
    p {
      margin: 5px 0;
    }

    form input[type=email] {
      margin: 10px 0;
    }

    form {
      p.lists {
        margin: 0 0 10px;
      }

      p.links {
        margin-top: 10px;

        a:nth-child(2) {
          margin-left: 140px;
        }
      }
    }
  }
}

@media screen and (max-height: 320px) {
  #mailing-list-popup {
    p {
      margin: 0;
    }
    form {
      .head {
        font-size: 18px;
      }

      input [type=email] {
        margin: 5px 0;
      }

      p.lists {
        margin: 0 0 5px;
      }

      p.links {
        margin-top: 5px;
      }
    }
  }
}

#mailing-list-preview {
  @media only screen and (max-width: 768px) {
    &.modal {
      position: fixed;
      z-index: 10;
      top: unset;
      right: unset;
      bottom: 0px;
      left: calc(50% - 165px);
      width: 330px;
      & .modal-dialog {
        margin: 0 !important;
        & .modal-header {
          margin: 0.5rem;
          & .modal-title {
            text-align: left;
            font-size: 18px;
            line-height: 1.5em;
            font-family: 'GloberRegular', Arial, Helvetica, sans-serif;
          }
          & img{
            position: absolute;
            right: 15px;
            top: 0px;
          }
        }
        & .modal-body {
          margin: 0rem 1rem 1.25rem 1rem;
          padding: 0;
        }
      }
    }
  }
}

// Region Detection Popup
#region-detection {
  &.modal {
    .modal-backdrop { background: black !important; }
    .modal-header button.close {
      padding: 0.75rem !important;
      @media screen and (max-width: 455px) {
        top: -14px!important;
        right: -15px!important;
      }
    }
    .modal-body {
      a { text-decoration: none; }
      p { font-size: 16px; }
      p.shipping-option { font-size: 10px; line-height: 1.5; font-family: 'GloberRegular', Arial, Helvetica, sans-serif; }
    }
  }
}

@media screen and (max-width: 455px) {
  #region-detection {
    .modal-content {
      padding: 30px;

      .modal-body {
        padding: 0;
        div a.btn {
          font-size: 16px !important;
          padding: 16px 10px;
          margin: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  #region-detection {
    .modal-content {
      div a.btn {
        position: absolute;
        left: -15px;
        width: 270px !important;
      }

      div.close-region {
        margin-top: 60px !important;
      }
    }
  }
}

// Contact Us Popup
#contact_us {
  .modal-content {
    a {
      color: #0071d1;
    }
  }
}
