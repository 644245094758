// fade
.fade-enter-active, .fade-leave-active { transition: opacity .5s; }
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  position: absolute;
}

// bounce
.bounce-enter-active { animation: bounce-in .5s; }
.bounce-leave-active { animation: bounce-in .5s reverse; }
@keyframes bounce-in {
  0% { transform: scale(0); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

.slideSide-enter, .slideSide-leave-to {
  opacity: 0;
  transform: translateY(30px);
  position: relative;
  z-index: 1;
}
.slideSide-leave-to { width: 2px; }
.slideSide-leave-active {
  position: absolute;
  z-index: 1;
}
