/* Font */
$black: #151515;
$light-black: #4d4d4d;
$grey: #f1f1f1;
$light-grey: #d4c8c8;
$blue: #0071d1;

.globerRegular {
  font-family: "GloberRegular", Arial, Helvetica, sans-serif;
}

.globerxBold {
  font-family: "GloberxBold", Arial, Helvetica, sans-serif;
}

.globerBold {
  font-family: "GloberBold", Arial, Helvetica, sans-serif;
}

.globerSemiBold {
  font-family: "GloberSemibold", Arial, Helvetica, sans-serif;
}

.globerBook {
  font-family: "GloberBook", Arial, Helvetica, sans-serif;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

@for $i from 0 to 3 {
  $step: 25;
  .font-#{$i}em {
    font-size: #{$i}em;
  }
  .font-#{$i}-#{$step}em {
    font-size: #{$i + ($step*0.01)}em;
  }
  .font-#{$i}-#{$step * 2}em {
    font-size: #{$i + ($step * 2*0.01)}em;
  }
  .font-#{$i}-#{$step * 3}em {
    font-size: #{$i + ($step * 3*0.01)}em;
  }
}

.font-1rem {
  font-size: 1rem;
}

.lh-1\.5 {
  line-height: 1.5;
}

@for $i from 0 to 7 {
  $step: 100;
  .text-weight-#{$i * $step} {
    font-weight: #{$i * $step} !important;
  }
}

.YS {
  &__display {
    & p {
      font-size: 12px;
      font-weight: 500;
      @extend .globerRegular;
    }
    & p a {
      font-size: 10px;
      @extend .globerRegular;
      text-decoration: underline;
      font-weight: bolder;
      color: grey;
    }
    & h1 {
      font-size: 35px;
      @extend .globerBold;
      font-weight: 600;
    }
    & h3 {
      font-size: 19px;
      @extend .globerSemiBold;
      font-weight: 500;
      padding-top: 1em;
      padding-bottom: 1em;
    }
    & h4 {
      font-size: 16px;
      @extend .globerSemiBold;
      padding-top: 1em;
    }
    & text {
      &-weight-700 {
        font-weight: 700 !important;
      }
      &-weight-600 {
        font-weight: 600 !important;
      }
      &-weight-400 {
        font-weight: 400 !important;
      }
    }
  }
}
//Desktop Styling
@media (min-width: 992px) {
  .YS {
    &__display {
      & p.first-paragraph {
        font-size: 18px;
        line-height: 22px;
      }
      & p {
        font-size: 14px;
        font-weight: 500;
        font-family: "globerRegular";
      }

      & p a {
        font-size: 12px;
        font-family: "globerRegular";
        text-decoration: underline;
        font-weight: bolder;
        color: grey;
      }
      & h1 {
        font-size: 35px;
        font-family: "globerBold";
        font-weight: 600;
      }
      & h3 {
        font-size: 25px;
        font-family: "globerSemiBold";
        font-weight: 500;
        padding-top: 1em;
        padding-bottom: 1em;
      }
      & h4 {
        font-size: 16px;
        font-family: "globerSemiBold";
        padding-top: 1em;
      }
      & .text-large {
        font-family: "globerRegular";
        font-size: 25px !important;
        line-height: 33px;
        & u {
          font-weight: bold;
        }
      }
      & text {
        &-weight-700 {
          font-weight: 700 !important;
        }
        &-weight-600 {
          font-weight: 600 !important;
        }
        &-weight-400 {
          font-weight: 400 !important;
        }
      }
    }
  }
}
