// Tags
.tags{
  margin: 5px 0 0px 0;
  padding: 5px 0;
  overflow: hidden;
  a.link{
    padding: 4px;
    color: $color-link;
    display: block;
    font-size: 12px;
  }

  .tag{
    padding-right: 10px;
    a{
      display: block;
      float: left;
      padding: 6px 3px;
      font-size: 10px;
      margin-top: -3px;
    }
    h4{
      font-size: 12px;
      float: left;
      padding: 0 5px;
      margin: 7px 1px;
      text-transform: capitalize;
    }
  }
  li{
    float: left;
    display: inline-block;
  }
}
