@import 'partials/color';

#wrapper { 
  min-height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

#main { 
  width: 85%;
  margin: 0 auto;
  position: relative; 
}

img {
  &.icon {
    width: 35px;
    margin: 15px 5px 15px 0;
  }
}

.btn-block {
  padding-left: 0;
  padding-right: 0;
}

.tip-info-btn {
  &.active {
    box-shadow: none;
    color: $color-link;
    border-bottom: solid 2px $color-link;
  }
}

.easyzoom {
  .product-image--zoomIcon { display: none; }
  &:hover { .product-image--zoomIcon { display: block; } }
}

#collections {
  .glyphicon.glyphicon-chevron-right {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-right: 1px solid black;
    border-top: 1px solid black;
    transform: rotate(45deg);
    margin-right: 0.5em;
    font-family: inherit;
    color: #ffffff00;
    top: 4px;
    right: -10px;
  }

  .glyphicon.glyphicon-chevron-left {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-right: 1px solid black;
    border-top: 1px solid black;
    transform: rotate(225deg);
    margin-right: 0.5em;
    font-family: inherit;
    color: #ffff00;
    top: 4px;
    left: -10px;
  }
}

.tip-info {
  background: black;
  padding: 15px;
  color: white;
  position: relative;
  display: block;
  width: 230px;
  display: none;

  &.visible { display: block; }

  .tip-close {
    position: absolute;
    top: 5px;
    right: 10px;
    color: white;
    font-size: 11px;
    font-weight: lighter;
  }
  
  p {
    margin: 0;
    font-size: 12px;
  }
}

.accordion {
  &:hover { cursor: pointer; }
  h4 { margin-bottom: 14px; }
  .text-right { &::after { content: '+'; } }
  .accordion-content, .wrapper {
    display: none;
    margin-bottom: 0;
  }
  &.active {
    .text-right { &::after { content: '-'; } }
    .accordion-content, .wrapper { display: block; }
  }
}

.btn-group {
  .selected {
    padding: 5px 0 ;
    margin: 0 25px 0 0 ;
    border-radius: 0;
    color: $color-link;
    border-bottom: 1px solid $color-link;
  }
}

span.required {
  color:$color-error;
  font-weight: bolder;
  font-size: 140%; 
}

.lighter { color: $color-light-slate; }

.font-small { font-size: 10px; }

.tab { display: none; }

.carousel-indicators li {
  margin-right: 15px;
  margin-left: 15px;
  width: 56px;
  
}

.carousel-caption {
  left:inherit;
  right: inherit;
  bottom:inherit;
  position: absolute;
  display: block;
  top: 50px;
  left: 3%;
  transform: translateY(0%);
  width: 100%;
  text-align: left;

  .btn-primary { border: none; }

  h3 {
    &.ys-black { color: black; }
    &.ys-white { color: white; }
  }

  a {
    font-family: 'GloberBold', Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
}

@include media-query($small) {
  #slider .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    top: 20%;
    bottom: inherit;
    left: 10%;
    width: 100%;
  }
}
.carousel-indicators {
  bottom: 10px;
  @include media-query($medium-up) { bottom: 28px; }
}
