$color-white: #ffffff; //white
$color-black: #000000; //black
$color-slate: #414A52; //body font
$color-light-slate: #5E6265;
$color-light-grey: #F4F6F9; //light section
$color-dark-grey: #8A9097;
$color-med-grey: #a5a5a5;
$color-teal: #7AE2DE; //cta
$color-blue: #2badf2;
$color-dark-teal: #71D5D2; //cta-hover
$color-porcelain: #E5E7E9; //keylines
$color-link: #037DDD; //keylines
$grey: #cacaca;
$grey-light: #f2f2f2;
$forms-grey: #A2A3A9;
$forms-dark: #262626;
$black: #121212;
$black-shadow:rgba(17, 17, 17, 0.28);
$color-link: #037DDD;
$color-error: #EE423C;
$color-contrast: #FF5D00;
$color-warn: $color-contrast;
$color-ok: #3AD24E;
$color-warging: #EBF7AC;


$SOLE-blue: #0071D1;
$SOLE-black: #000;
$SOLE-dark: #000;
$SOLE-grey: #f5f5f5;
$SOLE-border-grey: #e9ecef;
$SOLE-dark-grey: #CDCDCD;
$SOLE-white: white;
$SOLE-lighter-gray: #e7e7e7;

/* Color from cart overlay */
$SOLE-heart: rgb(219, 30, 30);
$SOLE-danger: #f00;
$SOLE-hover-blue: #147fda;
$SOLE-disabled: #818387;

/* Made For You */
$SOLE-made-for-you: #10B669;

/* Different naming */
$blue: #0071d1;
$black: #000;
$grey: #F5F5F5;

$SOLE-theme-colors: (
  '$SOLE-blue': #0071D1,
  '$SOLE-black': #000,
  '$SOLE-dark': #000,
  '$SOLE-grey': #f5f5f5,
  '$SOLE-border-grey': #e9ecef,
  '$SOLE-dark-grey': #CDCDCD,
  '$SOLE-white': white,
  /* Color from cart overlay */
  '$SOLE-heart': rgb(219, 30, 30),
  '$SOLE-danger': #f00,
  '$SOLE-hover-blue': #147fda,
  '$SOLE-disabled': #818387,
  '$SOLE-lighter-gray': #e7e7e7,
  /* Made For You */
  '$SOLE-made-for-you': #10B669
);
