.reviews{
  .reviews-nav{
    border-top: 1px solid $color-med-grey;
    border-bottom: 1px solid $color-med-grey ;
    padding: 25px 0;
    .btn-primary{
      float: right;
    }
  }
  .review{
    margin-bottom: 20px;
    h5{
      margin-bottom: 5px;
    }
    .review-date{
      color: #848484;
      font-size: 14px;
    }
    .review-author{
      font-family: 'GloberBold', Arial, Helvetica, sans-serif;
    }
    .review-content{
      max-width: 400px;
    }
    .review-vote{
      border-top: 1px solid $color-med-grey;
      border-bottom: 1px solid $color-med-grey ;
      padding: 15px 0;
      a{
        font-family: 'GloberBold', Arial, Helvetica, sans-serif;
      }
    }
  }
}

.reviews-pagination{
  padding: 10px 0 20px 0;
  text-align: center;
  border-bottom: 1px solid $color-med-grey ;
  margin-bottom: 30px;
  margin-left: -$grid-gutter-width * 0.5 ;
  margin-right: -$grid-gutter-width * 0.5 ;
  a{
    display: inline-block;
    padding: 1px 5px;
  }
}

#add-review{
  padding: 30px 0;
  background: $grey-light;

  .form-control{
    background-color: $grey-light;
    margin-top: 0;
  }
}
