/* .squaredThree */

.laberHeader{
  padding-right: 20px;
}
.tabNavigation{
  display: block;
  // overflow: hidden;
  border-bottom: solid 1px black;
  margin-bottom: 45px;
  
  ul{
    margin: 0;
    padding-left: 0;
  }
  li{
  // 
    width: 32%;
    display: inline-block;
    padding: 0;
    margin: 0;
    text-align: center;
    padding-bottom: 2px;

    &.active{
      a{
        color: $color-link;
        border-bottom: solid 4px $color-link;
      }
    }
  }
  a{
    color: black;
    text-decoration: none;
    padding-bottom:5px; 
  }
}

label{
  font-weight: inherit;
}

.progressBar{
  display: block;
  border-bottom: solid 1px $color-slate;
  height: 12px;
  margin-bottom: 30px;

  .progressBarMarker{
    display: inline-block;
    height: 4px;
    background: $color-slate;
    width: 60px;
    position: relative;
    transition: left .3s
  }

}

.alertBox {
  margin: 10px 0 5px 0;
  padding: 10px;
  text-align: center;
  background: $color-warging;
  p{
    margin: 0;
  }
  strong{
    color: $color-error;
  }
}

.checkbox{
  &--rate{
    margin-bottom: 20px;

    .radio-toolbar {
      margin: 0 -10px;

      span{
        vertical-align: middle;
        display: inline-block;
        height: 38px;
        padding-left: 6px;
      }

      & input[type="radio"] {
        display: none;
      }
    
      & label {
        display: inline-block;
        background-color: $grey-light;
        padding: 4px 11px;
        height: 34px;
        width: 34px;
        font-family: Arial;
        font-size: 10px;
        cursor: pointer;
        margin: 0 2%;
        border: solid 1px lighten($color-light-slate , 25%);
      }
      
      & input[type="radio"]:checked+label {
        border: solid 1px $color-light-slate;
        background-color: $color-link;
        -moz-box-shadow:    inset 0px 0px 0px 4px $grey-light;
        -webkit-box-shadow: inset 0px 0px 0px 4px $grey-light;
        box-shadow:         inset 0px 0px 0px 4px $grey-light;
      }
    } 
  }
}





.checkbox {
  width: 20px;
  position: relative;
  margin: 15px 10px 0 0px;
  display: block;

  label {
    width: 12px;
    height: 12px;
    cursor: pointer;
    position:relative;
    min-height: inherit;
    padding-left: 5px;
    top: 0;
    left: 0;
    border: solid 1px;
    &:after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: -1px;
      left: -1px;
      border: solid 1px;
      background: #037DDD;
      opacity: 0;
      // transform: rotate(-45deg);
      color: black;
    }
    &:hover::after {
      opacity: 0.9;
    }
  
    // input[type=checkbox]:checked + label:after
  
    input[type=checkbox] {
     
      &:checked + label:after{
        font-size: 30px;
        opacity: 1;
      }
      &:checked + label:after {
        opacity: 1;
        // visibility: hidden;
        // display: inline-block;
      
      }

    }
  }
  .text-label{
    padding-left: 15px;
    top: -12px;
    position: absolute;
    width: 300px;
    display: block;
  }

  .checkbox-material{
    padding-left: 20px;
    display: inline-block;
  }
}

.form-control{
  border-radius: 0px !important;
  border: 1px solid black;
  -webkit-border-radius: 0px; 
  -moz-border-radius: 0px; 
  }

textarea.form-control{
  border: solid 1px black;
  padding-left: inherit;
}

.form-control{
  

  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-color: $forms-dark;
  color: black;
  margin: 13px 0;
  padding-left: 0;

  &.signHere{
    padding: 10px 20px;
    font-size: 20px;
    height: 150px;
    line-height: 120px;
    border: solid 1px black;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
  }

  &:focus{
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: $color-link;
  }

  &.error{
    border-bottom: 3px solid $color-error;
    text-align: left;
   
  }
}


/* SQUARED ONE */
.squaredOne {
	width: 28px;
	height: 28px;
	background: #fcfff4;

	background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
	background: -moz-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
	background: -o-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
	background: -ms-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
	background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfff4', endColorstr='#b3bead',GradientType=0 );
	margin: 20px auto;
	-webkit-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
	-moz-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
	box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
	position: relative;
}

.squaredOne label {
	cursor: pointer;
	position: absolute;
	width: 20px;
	height: 20px;
	left: 4px;
	top: 4px;

	-webkit-box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,1);
	-moz-box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,1);
	box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,1);

	background: -webkit-linear-gradient(top, #222 0%, #45484d 100%);
	background: -moz-linear-gradient(top, #222 0%, #45484d 100%);
	background: -o-linear-gradient(top, #222 0%, #45484d 100%);
	background: -ms-linear-gradient(top, #222 0%, #45484d 100%);
	background: linear-gradient(top, #222 0%, #45484d 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222', endColorstr='#45484d',GradientType=0 );
}

.squaredOne label:after {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	background: #00bf00;

	background: -webkit-linear-gradient(top, #00bf00 0%, #009400 100%);
	background: -moz-linear-gradient(top, #00bf00 0%, #009400 100%);
	background: -o-linear-gradient(top, #00bf00 0%, #009400 100%);
	background: -ms-linear-gradient(top, #00bf00 0%, #009400 100%);
	background: linear-gradient(top, #00bf00 0%, #009400 100%);

	top: 2px;
	left: 2px;


}

.squaredOne label:hover::after {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
	filter: alpha(opacity=30);
	opacity: 0.3;
}

.squaredOne input[type=checkbox]:checked + label:after {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}

