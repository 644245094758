#guarantee {
  @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);
  $SOLE-blue: #0071d1;
  $SOLE-grey: #818387;

  height: 100%;
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;

  h5, .h5 {
    font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
    color: black;
    font-size: 26px;
  }
  
  .btn {
    font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
    padding: 5px 22px 5px 22px;
  }

  p {
    font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 20px;
    width: 85%; 
    margin: 0px auto 20px auto;
    color: black;

    &.h5 {
      font-size: 26px;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      margin: 15px auto;
      line-height: 1.2;
      text-align: center;
      color: black;
    }
  }

  hr {
    width: 85%;
    background: black;
    margin: auto;
  }

  a {
    text-decoration: underline;
    color: $SOLE-blue;
    &:hover { color: black; }
  }

  .modal-title {
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .modal-content {
    padding: 40px;

    .modal-header {
      poistition: relative;
      padding: 0;
      border-bottom: 0;
      .close { 
        position: relative;
        padding: 0;
        padding-right: 1rem;
        position: relative;
        opacity: 0.5;
      }
      button.close {
        position: relative;
        top: 0;
        botton: 0;
        font: unset;
        font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 300;
      }
    }
  }
  .modal-body {
    padding:15px;
  }

  .guarantee-page {width: 100%;height: 100%;}
  .guarantee-page img {display: block;margin: 0 auto 25px !important;width: 79px;height: 79px;}
  .guarantee-page ul { font-size: 0; margin: -25px auto !important; width: 100%; height: 80%; overflow: hidden; }
  .guarantee-page ul li { vertical-align: top; display: block; font-size: 12px; width: 100%; }
  .guarantee-page p {font-size: 14px;line-height: 20px;clear: both;}
  .guarantee-page p a {font-size: 14px;font-wieght: 900; color: #1e6bb8;text-decoration: underline;}

  /* DESKTOP BREAKPOINT */
  @media only screen and (min-width: 960px) {

    .guarantee-page ul {font-size: 0;margin: -25px auto !important;width: 95%;}
    .guarantee-page ul li {vertical-align: top;display: inline-block;font-size: 12px;width: 50%;height: 140px;margin-top: -10px;}

  }

  /* MOBILE BREAKPOINT */
  @media only screen and (max-width: 599px) {

    h5 {
      font-size: 26px;
      color: black;
      font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
    }

    .modal-content {
      margin-left: .5rem;
      margin-right: .5rem;
    }

    .modal-dialogue {
      margin: .5rem;
    }

  }
}
