&[v-cloak] .v-cloak--block {
  display: block;
}

&[v-cloak] .v-cloak--flex {
  display: flex;
}

&[v-cloak] .v-cloak--hidden {
  display: none;
}

& .v-cloak--block,
& .v-cloak--flex {
  display: none;
}