.space1,
.space2,
.space3,
.space4,
.space5,
.space6,
.space7 {
  display: block;
}
.space1 {
  height: 10px;
}
.space2 {
  height: 20px;
}
.space3 {
  height: 30px;
}
.space5 {
  height: 50px;
}
.space4 {
  height: 40px;
}
.space6 {
  height: 60px;
}
.space7 {
  height: 70px;
}
.no-content {
  display: none;
}
.no-border {
  border: none;
}
.diviser {
  border-bottom: 1px solid lighten($color-med-grey, 12%);
  display: block;
  height: 2px;
  margin-bottom: 23px;
}
.oldprice {
  color: $color-dark-grey;
  text-decoration: line-through;
  vertical-align: top;
}
span.text-right {
  float: right;
}

// Color
.color-warn {
  color: $color-contrast;
  strong {
    color: $color-contrast;
  }
}

.color-alert {
  color: $color-error;
  strong {
    color: $color-error;
  }
}

// sortByLowPrice
.bold-text {
  font-size: 16px;
  font-family: "GloberxBold", Arial, Helvetica, sans-serif;
}

.padding-right-min {
  margin: 0px !important;
  padding: 0 !important;
  padding-right: 5px !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

#panel {
  overflow: hidden;
}
.carousel {
  background: #acaea9;
}
.gallery-top {
  height: 80%;
  width: 100%;
}

.icon-new {
  width: 28px;
  margin: 0 10px;
  vertical-align: middle;
}
.sale {
  text-decoration: line-through;
}
.fix-bottom {
  overflow: hidden;
  height: 55px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #d5d5d5;
  right: 0px;
  z-index: 10000;

  .btn-icon {
    padding: 10px 6px;
    border: solid 1px grey;
    display: inline-block;
    margin: 8px 5px;
    vertical-align: middle;
    height: 40px;
  }
  .btn-icon:before,
  .frame_before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  img {
    vertical-align: middle;
    max-height: 25px;
    max-width: 160px;
  }

  .col-xs-6:first-child {
    margin-right: solid 1px black;
  }

  .btn-block {
    height: 55px;
    font-size: 16px;
    text-decoration: none;
    padding-top: 15px;
  }
}

.search-bar {
  position: relative;
  margin: 25px 0 20px 0;
  .glyphicon {
    position: absolute;
    right: 8px;
    top: 10px;
    font-size: 16px;
  }
}
.underline {
  border-bottom: solid 1px black;
}

.product-list-view {
  img {
    width: 100%;
  }
  .item {
    margin-bottom: 20px;
    border-bottom: solid 1px;
    padding-bottom: 15px;
  }
}

.grey-bg {
  background: #b5b5b5;
}

.carousel-control.left,
.carousel-control.right {
  background: none;
}

.no-underline {
  text-decoration: none;
}
.hero {
  background: #e0dfdf;
}

.carousel-inner {
  .item {
    height: 200px;
    background: darken($grey, 10%);
  }
}

.jumbotron {
  margin-bottom: 0;
  .btn-primary {
    background: black;
    color: #fcf5f5;
  }

  .container & {
    border-radius: 0;
  }

  &.v2 {
    background: #343030;
    h1,
    p,
    h2,
    h3 {
      color: white;
    }

    .btn-primary {
      background: #fcf5f5;
      color: black;
    }
  }

  &.v3 {
    background: #a8a3a3;
    h1,
    p,
    h3 {
      color: white;
    }

    .btn-primary {
      background: #fcf5f5;
      color: black;
    }
  }
}

.moreinfo {
  font-size: 19px;
  border-bottom: solid 1px;
  padding: 12px;
  display: block;
  position: relative;
  color: black;
  margin-bottom: 4px;

  text-decoration: none;
  &:after {
    position: absolute;
    content: "+";
    right: 10px;
    font-size: 19px;
  }
}

.product-tbn {
  padding: 0 15px;
  img {
    width: 31%;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
  }
}

.showBtn {
  padding: 20px 0;
  display: block;
}
